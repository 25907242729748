import React, {useCallback, useEffect} from 'react';
import {
  BrowserRouter,
  Routes,
  Route, Navigate,
} from 'react-router-dom'
import { useStoreState,useStoreActions } from 'easy-peasy';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/scss/bootstrap.scss'
import './App.css';
import './dashboard.css'

import {
  Menu,
  Merchants,
  ProductsByMerchant,
  Products,
  Users,
  UserInfo,
  Categories,
  Countries,
  Photos,
  AddPhoto,
  AddMerchant,
  Subscriptions
} from './components'
import UnapprovedMerchants from "./components/Merchant/UnapprovedMerchants";
import Orders from "./components/Orders/Orders";
import Login from "./components/Login/Login";
import axiosInstance ,{setAxiosToken} from "./services/axios";
import './styles.scss'
import HeaderBar from "./components/HeaderBar";

const App= ()=>{
  const {user,access_token} = useStoreState((state) => state.app);
  const {setToken, setUser} = useStoreActions((actions) => actions.app);

  useEffect(() => {
    if(access_token && !user){
      axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
      setAxiosToken(access_token);
      axiosInstance.get('/users/info')
          .then(({data}) => {
           setUser(data)
            localStorage.setItem('user', JSON.stringify({email:data.email}))
          }).catch(error => {
            localStorage.clear();
            console.log('/users/info',error);
            setAxiosToken(null);
            setUser(null);
            setToken(null);
      })
    }
  }, [access_token, user]);

  const logout = useCallback((event) => {
    event.preventDefault()
    setToken(null)
    setUser(null)
    localStorage.clear()
  },[setUser, setToken])

  return (
    <BrowserRouter>
      <div className="container-fuid px-0">
      {access_token && user && <HeaderBar logout={logout} />}

        <div className="row">
          {access_token && user && <>
          <div className="col col-md-2 d-none d-md-block bg-dark">
            <Menu/>
          </div>
          <div className="col col-md-10 ml-sm-auto col-lg-10 pt-3 px-4 pb-5 mb-5"><Routes>
            <Route path="/merchants/:id" element={<ProductsByMerchant/>}/>
            <Route path="/merchants/create" element={<AddMerchant/>}/>
            <Route path="/merchants" element={<Merchants/>}/>
            <Route path="/merchants/:id" element={<ProductsByMerchant/>}/>
            <Route path="/merchants-unapproved" element={<UnapprovedMerchants/>}/>
            <Route path="/products" element={<Products/>}/>
            <Route path="/users/:phoneNumber" element={<UserInfo/>}/>
            <Route path="/users" element={<Users/>}/>
            <Route path="/categories" element={<Categories/>}/>
            <Route path="/countries" element={<Countries/>}/>
            <Route path="/photos" element={<Photos/>}/>
            <Route path="/add-photos" element={<AddPhoto/>}/>
            <Route path="/orders" element={<Orders/>}/>
            <Route path="/subscriptions" element={<Subscriptions/>}/>
            <Route
                path="*"
                element={<Navigate to="/merchants" replace />}
            />
          </Routes>
          </div></>
          }
          {(!access_token) &&
          <main role="main" className="col-md-12 ml-sm-auto col-lg-12 pt-3 px-4 pb-5 mb-5">
            <Routes>
              <Route path="/login" element={<Login/>}/>
              <Route
                  path="*"
                  element={<Navigate to="/login" replace />}
              />
            </Routes>
          </main>
          }
        </div>
      </div>
    </BrowserRouter>
  );

}
export default App;
