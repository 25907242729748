import React from "react"
import {useNavigate} from "react-router-dom";
import moment from "moment"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCog,faCheck,faBan } from '@fortawesome/free-solid-svg-icons'

const c_green = '#28a745'
const c_red = '#DC3545'

const Merchant =(props)=>{
    const { setPriority, setPublishMap, setPublishWeb,deleteMerchant,selectMerchant,data,setPublish,loginOnMerchant, setApproved, nElem,nPage}=props
    const navigate = useNavigate()
    const _setPublishWeb=(evt)=>{
        evt.stopPropagation()
        setPublishWeb(data.id,!data.publishOnWeb)
    }

    const _toggleApproved = (evt) => {
        evt.stopPropagation()
        setApproved(data.id,!data.approved, nElem, nPage)
    }

    const _setPublishMap=(evt)=>{
        evt.stopPropagation()
        setPublishMap(data.id,!data.publishOnMap)
    }

    const _setPriority=(evt)=>{
        evt.stopPropagation()
        let p = (data.priority === 0 || data.priority === null)
        setPriority(data.id,p)
    }
    const _deleteMerchant=(evt)=>{
        evt.stopPropagation()
        deleteMerchant(data.id)
    }


    const _selectMerchant=(evt)=>{
        evt.stopPropagation()
        selectMerchant(data)
        navigate(`/merchants/${data.id}`)
    }

    const _setPublishApp=(evt)=>{
        evt.stopPropagation()
        setPublish(data.id,!data.publish)
    }

    const _loginMerchant=evt=>{
        evt.stopPropagation()
        loginOnMerchant(data.userId)
    }

    const getSlugLink=()=>{
        const {id,country,publishOnWeb,slug}=data
        const publishHash = "hash.digest('hex')";
        const queryString = publishOnWeb === false ? `?publish-hash=${publishHash}` : ''
        const  domain = country === 'NL'? 'yuge.nl' : country === 'US' ? 'goyuge.com' : 'yuge.es';
        const href = `http://www.${domain}/shops/${slug}${queryString}`
        return <a target={"_blank"}  rel="noreferrer" href={href} style={{textDecorationLine: 'underline'}}> {slug}</a>
    }

    const _setPaymentStatus=event=>{
        event.preventDefault()
        props.setPaymentStatus(data.id,!data.enabledToSales)
    }

    return (<tr>
        <td className="align-middle text-start ps-2"><a onClick={_selectMerchant} style={{paddingLeft:'3px', cursor: 'pointer',textDecorationLine: 'underline' ,color: (data.publishOnWeb===true && data.publishOnMap)?'#007bff':c_red}}> {data.companyName}</a></td>
        <td className="align-middle text-start ps-2">{getSlugLink()}</td>
        <td className="align-middle text-start">{data.address}</td>
        <td className="align-middle ps-4">{data.postalCode}</td>
        <td className="align-middle text-start ps-4">{data.email}</td>
        <td className="align-middle text-start ps-4">{data.name}</td>
        <td className="align-middle text-center">{data.productCount}</td>
        <td className="align-middle text-center">{data.openingHours === null? <FontAwesomeIcon color={'red'} icon={faBan}/>: <FontAwesomeIcon color={'green'} icon={faCheck}/>}</td>
        <td className="align-middle text-center">{data?.images?.length > 0?<FontAwesomeIcon color={'green'} icon={faCheck}/>:<FontAwesomeIcon color={'red'} icon={faBan}/> }</td>
        <td className="align-middle text-center">{!data.publishOnWeb?<FontAwesomeIcon color={'red'} icon={faBan}/>:<FontAwesomeIcon color={'green'} icon={faCheck}/>}</td>
        <td className="align-middle text-center">{!data.publishOnMap?<FontAwesomeIcon color={'red'} icon={faBan}/>:<FontAwesomeIcon color={'green'} icon={faCheck}/>}</td>
        <td className="align-middle text-center">{!data.publish?<FontAwesomeIcon color={'red'} icon={faBan}/>:<FontAwesomeIcon color={'green'} icon={faCheck}/>}</td>
        <td className="align-middle text-center">{!data.enabledToSales?<FontAwesomeIcon color={'red'} icon={faBan}/>:<FontAwesomeIcon color={'green'} icon={faCheck}/>}</td>
        <td className="align-middle text-center">{(data.priority === 0 || data.priority === null)?<FontAwesomeIcon color={'red'} icon={faBan}/>:<FontAwesomeIcon color={'green'} icon={faCheck}/>}</td>
        <td className="align-middle text-start ps-4">{data.salesPerson}</td>
        <td className="align-middle ps-4">{moment(data.created).format('YYYY/MM/DD')}</td>
        <td className="align-middle text-center">
            <div className="dropdown">
                <button
                    className="btn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <FontAwesomeIcon color={'red'} icon={faCog}/>
                </button>
                {data.approved === true && <ul className="dropdown-menu">
                    <li><a className="dropdown-item"
                           onClick={_toggleApproved}>{data.approved === true ? 'Set unapproved' : 'Set approved'}</a>
                    </li>
                    <div className="dropdown-divider"/>
                    <li> <a className="dropdown-item"
                            onClick={_setPublishWeb}>{data.publishOnWeb ? 'Unpublish on Web' : 'Publish on Web'}</a>
                    </li>
                    <div className="dropdown-divider"/>
                    <li><a className="dropdown-item"
                           onClick={_setPublishMap}>{data.publishOnMap ? 'Unpublish on Map' : 'Publish on Map'}</a>
                    </li>
                    <div className="dropdown-divider"/>
                    <li><a className="dropdown-item"
                           onClick={_setPublishApp}>{data.publish ? 'Unpublish on App' : 'Publish on App'}</a>
                    </li>
                    <div className="dropdown-divider"/>
                    <li>
                        <a className="dropdown-item"
                           onClick={_setPriority}>{(data.priority === 0 || data.priority === null) ? 'Recommend' : 'UnRecommend'}</a>

                    </li>
                    <div className="dropdown-divider"/>
                    <li>
                        <a className="dropdown-item" onClick={_loginMerchant}>Login</a>
                    </li>
                    <div className="dropdown-divider"/>
                    <li>
                        <a className="dropdown-item"
                           onClick={_setPaymentStatus}>{data.enabledToSales === true ? 'Unpublish Payment' : 'Publish Payment'}</a>

                    </li>
                    <div className="dropdown-divider"/>
                    <li>
                        <a className="dropdown-item" onClick={_deleteMerchant}>Delete</a>
                    </li>
                </ul>
                }
                {!data.approved &&
                    <ul className="dropdown-menu">
                        <li><a className="dropdown-item" onClick={_toggleApproved}>{data.approved === true ? 'Set unapproved' : 'Set approved'}</a></li>
                        <div className="dropdown-divider"/>
                        <li>
                            <a className="dropdown-item" onClick={_deleteMerchant}>Delete</a>
                        </li>
                    </ul>
                }
            </div>
        </td>
    </tr>)
}
export default Merchant


