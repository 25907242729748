import {action, thunk} from "easy-peasy";
import axiosInstance from "../../services/axios";


export const createMerchant = thunk(async (actions, payload) => {
    actions.setData({key:'isSaving',value:true});
    axiosInstance.post('/users/register/admin',{
        ...payload
    }).then(()=>{
        actions.setData({key:'merchantCreated', value:true});
        actions.setData({key:'createMerchantError', value:null});
    }).catch(err=>{

        const errors = err?.response?.data?.detail;
        let errorDetail = 999;
        if(Array.isArray(errors)){
            errorDetail = errors[0]?.type;
        }
        console.log('create merchant error:', errorDetail)
        actions.setData({
            key:'createMerchantError',
            value:errorDetail})
    }).finally(()=> {
        actions.setData({key:'isSaving',value:false});
    })
});

export const fetchMerchants = thunk(async (actions, payload) => {
    actions.setData({key:'isFetching',value:true});
    const url = `/merchants/auth/search?approved=true&computeProductSummary=true&page=${payload.cPage}&elements=${payload.nElem}&computeTotal=true`
    axiosInstance.get(url).then(({data})=>{
        if(Array.isArray(data)) {
            actions.setData({key: 'merchants', value: data})
        }else{
            const {merchants, total} = data;
            actions.setData({key: 'error', value: null});
            actions.setData({key: 'merchants', value: merchants});
            actions.setData({key: 'paginator', value: {pages: Math.ceil(total / payload.nElem), elements: payload.nElem,total}});
        }
    }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(()=> {
        actions.setData({key:'isFetching',value:false});
    })
});

export const fetchMerchantsFilter= thunk(async (actions, payload) => {
    let url = `/merchants/auth/search?approved=true&computeProductSummary=true&computeTotal=true&page=${payload.cPage}&elements=${payload.nElem}`;
    if(payload.filter){
        Object.keys(payload.filter).forEach(key => {
            url += `&${key}=${payload.filter[key]}`;
        })
    }
    if(payload.order){
        Object.keys(payload.order).forEach(key => {
            if(!!payload.order[key])
                url += `&${key}=${payload.order[key]}`;
        })
    }
    //https://api.goyuge.com/v1/merchants/auth/search?approved=true&computeProductSummary=true&page=1&elements=100&computeTotal=true&sortByCreated=desc
    //https://api.goyuge.com/v1/merchants/auth/search?approved=false&computeProductSummary=true&computeTotal=true&page=1&elements=15&sortByCreated=desc
      actions.setData({key:'isFetching',value:true});
    axiosInstance.get(url).then(({data})=>{
        if(Array.isArray(data)) {
            actions.setData({key: 'merchants', value: data})
        }else{
            const {merchants, total} = data;
            actions.setData({key: 'merchants', value: merchants});
            actions.setData({key: 'paginator', value: {pages: Math.ceil(total / payload.nElem), elements: payload.nElem,total}});
            actions.setData({key: 'error', value: null});
        }
    }).catch(err=>{
        console.log('Error:', err)
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(()=> {
        actions.setData({key:'isFetching',value:false});
    });
})

const urls = {
    publish:'/merchants/publish',
    publishWeb:'/merchants/publish/web',
    publishMap:'/merchants/publish/map',
    publishPayment:'/sales/merchant',
    approved:'/merchants/approved',
    prioritize:'/merchants/prioritize',
}
export const onSet = thunk(async (actions, payload,{getStoreActions}) => {
    const {field= 'publish', value, merchantId, urlKey} = payload;
    actions.setData({key:'publishing',value:true});
    axiosInstance.put(urls[urlKey],{
        merchantId: merchantId,
        [field]: value
    }).then(({data})=> {
        if(field === 'approved' && value === false){
                const {unapprovedMerchants} = getStoreActions();
                unapprovedMerchants.getTotalUnapprovedMerchants();
        }
        actions.updateMerchant(data);
    }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(()=> {
        actions.setData({key:'publishing',value:false});
    })
});

export const orderByField = action((state,payload) => {
    const {field, order} = payload;

    const arr = [...state.merchants];
    if(order === 'desc'){
        arr.sort((a,b) => a[field] >= b[field] ? 1 : -1);
    }else{
        arr.sort((a,b) => a[field] <= b[field] ? 1 : -1);
    }
    state.merchants = arr;
});

export const getUserToken = thunk(async (actions, payload) => {
    actions.setData({key:'publishing',value:true});
    axiosInstance.post('/token/admin',{
        userId: payload.userId
    }).then(({data})=>{
        actions.setData({key:'token', value:data})
    }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(()=> {
        actions.setData({key:'publishing',value:false});
    })
});

export const onDeleteMerchant = thunk(async (actions, payload) => {
    actions.setData({key:'publishing',value:true});
    axiosInstance.delete(`/merchants/${payload.merchantId}`).then(()=>{
        actions.removeMerchant({id:payload.merchantId})
    }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(()=> {
        actions.setData({key:'publishing',value:false});
    })
});

export const getMerchantById=thunk(async (actions, payload) => {
    actions.setData({key:'publishing',value:true});
    axiosInstance.get(`/merchants/merchant/${payload.merchantId}`).then(({data})=>{
        actions.setData({key:'merchantSelected', value:data});
    }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(()=> {
        actions.setData({key:'publishing',value:false});
    })
})

