import React from "react"
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan, faCheck, faCog} from "@fortawesome/free-solid-svg-icons";


const UserRow=props=>{
    const{id,phoneNumber,currentOs,	currentOsVersion,	currentDevice,	modified,	created	,	phoneConfirmed,confirmationCodes=[],disabled}=props.data
    const _selectUser=(evt)=>{
        evt.stopPropagation()
        props.selectUser(props.data)
    }
    const _setUserEnable=(evt)=>{
        evt.stopPropagation()
        props.setUserEnable(id,!disabled)
    }

    return (
        <tr>
            <td className="align-middle text-start"><a style={{cursor: 'pointer',textDecorationLine: 'underline',color: '#007bff'}} onClick={_selectUser}>{phoneNumber}</a></td>
            <td className="align-middle text-center">{currentOs}</td>
            <td className="align-middle text-center">{currentOsVersion}</td>
            <td className="align-middle">{currentDevice}</td>
            <td className="align-middle">{moment(modified).format("YYYY/MM/DD HH:mm")}</td>
            <td className="align-middle">{moment(created).format("YYYY/MM/DD HH:mm")}</td>
            <td className="align-middle text-center">{confirmationCodes !== null? confirmationCodes.length:0}</td>
            <td className="align-middle text-center">{phoneConfirmed===true?<FontAwesomeIcon color={'#28a745'} icon={faCheck}/>:<FontAwesomeIcon color={'#dc3545'} icon={faBan}/>}</td>
            <td className="align-middle" style={{minWidth:'120px'}}>{disabled?<button type="button" className="btn btn-sm w-100 btn-outline-success" onClick={_setUserEnable}>
                <FontAwesomeIcon color={'#28a745'} icon={faCog}/>
                <span>{' Allow'}</span>
                </button>:
                <button type="button" className="btn btn-sm w-100 btn-outline-danger" onClick={_setUserEnable}>
                <FontAwesomeIcon color={'#dc3545'} icon={faBan}/>
                <span>{' Deny'}</span>
                </button>}
            </td>
        </tr>
    )
}

export default UserRow
