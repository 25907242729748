import {action, thunk} from "easy-peasy";
import axiosInstance from "../../services/axios";

export const fetchMerchants = thunk(async (actions, payload) => {

    actions.setData({key:'isFetching',value:true});
    const url = `/merchants/auth/search?approved=false&computeProductSummary=true&page=${payload.cPage}&elements=${payload.nElem}&computeTotal=true`
    axiosInstance.get(url).then(({data})=>{
        if(Array.isArray(data)) {
            actions.setData({key: 'merchants', value: data})
        }else{
            const {merchants, total} = data;
            actions.setData({key: 'merchants', value: merchants});
            actions.setData({key: 'paginator', value: {pages: Math.ceil(total / payload.nElem), elements: payload.nElem,total}});
        }
    }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(()=> {
        actions.setData({key:'isFetching',value:false});
    })
});


export const fetchMerchantsFilter= thunk(async (actions, payload) => {
    let url = `/merchants/auth/search?approved=false&computeProductSummary=true&computeTotal=true&page=${payload.cPage}&elements=${payload.nElem}`;

    if(payload.filter){
        Object.keys(payload.filter).forEach(key => {
            url += `&${key}=${payload.filter[key]}`;
        })
    }
    if(payload.order){
        Object.keys(payload.order).forEach(key => {
            if(!!payload.order[key])
                url += `&${key}=${payload.order[key]}`;
        })
    }
    actions.setData({key:'isFetching',value:true});
    axiosInstance.get(url).then(({data})=>{
        if(Array.isArray(data)) {
            actions.setData({key: 'merchants', value: data})
        }else{
            const {merchants, total} = data;
            actions.setData({key: 'error', value: null});
            actions.setData({key: 'merchants', value: merchants});
            actions.setData({key: 'paginator', value: {pages: Math.ceil(total / payload.nElem), elements: payload.nElem,total}});
        }
    }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(()=> {
        actions.setData({key:'isFetching',value:false});
    })
})

export const orderByField = action((state,payload) => {
    const {field, order} = payload;

    const arr = [...state.merchants];
    if(order === 'desc'){
        arr.sort((a,b) => a[field] >= b[field] ? 1 : -1);
    }else{
        arr.sort((a,b) => a[field] <= b[field] ? 1 : -1);
    }
    state.merchants = arr;
});

export const onSetApproved = thunk(async (actions, payload) => {
    const {field= 'publish', value, merchantId} = payload;
    actions.setData({key:'publishing',value:true});
    axiosInstance.put('/merchants/approved',{
        merchantId: merchantId,
        [field]: value
    }).then(({data})=>{
        actions.removeMerchant(data)
    }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(()=> {
        actions.setData({key:'publishing',value:false});
    })
});

export const onDeleteMerchant = thunk(async (actions, payload) => {
    actions.setData({key:'publishing',value:true});
    axiosInstance.delete(`/merchants/${payload.merchantId}`).then(()=>{
        actions.removeMerchant({id:payload.merchantId})
    }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(()=> {
        actions.setData({key:'publishing',value:false});
    })
});

export const getTotalUnapprovedMerchants = thunk(async (actions, payload) => {
    let url = `/merchants/auth/search?approved=false&computeProductSummary=true&computeTotal=true&page=1&elements=1`;
    actions.setData({key:'isFetching',value:true});
    axiosInstance.get(url).then(({data})=>{
        if(!Array.isArray(data)) {
            console.log('Data totalUnapproved:', data)
            const {total} = data;
            actions.setData({key: 'totalUnapproved', value: total});
        }
    }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(()=> {
        actions.setData({key:'isFetching',value:false});
    })

});




