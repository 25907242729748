import {action} from "easy-peasy";
import * as Actions from './actions'

const categoriesModel = {
    pagination:{pages: 0, elements: 0,total: 0},
    fetchingCategories:false,
    error:null,
    categories:[],
    fetchingPagination:false,
    publisching:false,
    setData:action((state,payload) => {
        state[payload.key] = payload.value
    }),
    update:action((state, payload) => {
        state.categories = [...state.categories].map(ele => ele.id === payload.id ? {...payload} : ele);
    }),

    fetchCategories: Actions.fetchCategories,
    onPublish: Actions.onPublish
}
export default categoriesModel;
