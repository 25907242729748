import React from "react";
import PropTypes from 'prop-types';
const Modal=props=>{
    return (props.isLoading? <div className="modal fade show" tabIndex="-1" role="dialog" style={{display: 'block'}}>
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                {
                    props.title?<div className="modal-header">
                        <h5 className="modal-title">{props.title}</h5>

                    </div>:<div/>
                }
                <div className="modal-body" style={{ display: 'flex', justifyContent: 'center'}}>
                    {
                        props.children
                    }
                </div>
            </div>
        </div>
    </div>:<div style={{
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        position: 'fixed',
        left: '0px',
        right: '0px',
        top: '0px',
        bottom: '0px',
        zIndex: '5000',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    }}>
        <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    </div>)
}
Modal.prototype={
    isLoading:PropTypes.bool.isRequired,
    title:PropTypes.string
}
export default Modal
