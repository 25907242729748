import React from "react";
import ReactCountryFlag from "react-country-flag"
export const CountryRow=props=>{
    return <tr>
        <td className="text-start ps-2">{props.data.key}</td>
        <td>{ <ReactCountryFlag
            style={{
                fontSize: '2em',
                lineHeight: '1em',
            }}
            countryCode={props.data.country} />}</td>
        <td >{props.data.country}</td>
        <td className="text-start ps-4">{props.data.countryCode}</td>
    </tr>
}
