import {thunk} from "easy-peasy";
import axiosInstance from "../../services/axios";

export const fetchCategories = thunk(async (actions, payload) => {

    actions.setData({key:'fetchingCategories',value:true});
    let url = `/categories?page=${payload.cPage}&elements=${payload.nElem}&computeTotal=true`;
    if(payload.filter){
        Object.keys(payload.filter).forEach(key => {
            url += `&${key}=${payload.filter[key]}`;
        })
    }
    axiosInstance.get(url).then(({data})=>{
        if(Array.isArray(data)) {
            actions.setData({key: 'categories', value: data})
        }else{
            const {categories, total} = data;
            actions.setData({key: 'categories', value: categories});
            actions.setData({key: 'pagination', value: {pages: Math.ceil(total / payload.nElem), elements: payload.nElem,total}});
        }
    }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(()=> {
        actions.setData({key:'fetchingCategories',value:false});
    })

});

export const onPublish = thunk(async (actions, payload) => {
    actions.setData({key:'publisching', value:true});
    axiosInstance.put('/categories/publish', {
        categoryId: payload.categoryId,
        publish:payload.publish
    }).then(({data})=>{
            actions.update(data)
        }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(() => {
        actions.setData({key:'publisching', value:false});
    })
});
