import React from 'react';
import moment from "moment";
const formatTime = (time,format='YYYY/MM/DD') => {
        if(!time){
                return '';
        }
        if(time.toString().length < 13){
            return moment(parseInt(time) * 1000).format(format);
        }else{
            return moment(time).format(format);
        }
}
const SubscriptionRow = ({subscription}) => {
    return (
        <tr>
            <td className="align-middle ps-4">{subscription.customer.name}</td>
            <td className="align-middle ps-4">{subscription.nickname}</td>
            <td className="align-middle ps-4">{subscription.customer.email}</td>
            <td className="align-middle text-center">{subscription.status.toString().toUpperCase()}</td>
            <td className="align-middle text-center">{formatTime(subscription.canceledAt)}</td>
            <td className="align-middle text-center">{formatTime(subscription.cancelAt)}</td>
            <td className="align-middle text-center">{subscription.cancelAtPeriodEnd ? 'Yes' : 'No'}</td>
            <td className="align-middle text-center">{formatTime(subscription.periodStart)}</td>
            <td className="align-middle text-center">{formatTime(subscription.periodEnd)}</td>
            <td className="align-middle text-center">{subscription.plan_id}</td>
            <td className="align-middle text-center">{subscription.amount.toLocaleString("en-US", {style:"currency", currency:"EUR"})}</td>
            <td className="align-middle text-center">{subscription.interval.toString().toUpperCase()}</td>
            <td className="align-middle text-center">{subscription.customer.disabled ? 'Yes' : 'No'}</td>
            <td className="align-middle text-center">{formatTime(subscription.customer.created,'YYYY/MM/DD HH:mm')}</td>
            <td className="align-middle text-center">{formatTime(subscription.customer.modified,'YYYY/MM/DD HH:mm')}</td>
            <td className="align-middle text-center">{subscription.customer.emailConfirmed ? 'Yes' : 'No'}</td>
            <td className="align-middle text-center">{subscription.customer.phoneNumber}</td>
            <td className="align-middle text-center">{subscription.customer.phoneConfirmed ? 'Yes' : 'No'}</td>
        </tr>
    );
}

export default SubscriptionRow;
