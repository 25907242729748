import {action, thunkOn} from "easy-peasy";
import * as Actions from "./actionsUnapproved";

const unapprovedMerchantsModel = {
    error:null,
    totalUnapproved:0,
    updatedAt:Date.now(),
    isFetching:false,
    paginator:{pages: 0, elements: 0,total: 0},
    merchants:[],
    token:null,
    publishing:false,
    setData:action((state,payload) => {
        state[payload.key] = payload.value
    }),
    addMerchant:action((state,payload) => {
         const temp = [...state.merchants];
        temp.push(payload);
        state.merchants = temp;
    }),
    onUnapprovedMerchant: thunkOn((actions, storeActions) => {
        return  storeActions.merchant.removeMerchant
    },(state, target) => {
       state.addMerchant(target.payload);
    }),
    removeMerchant:action((state, payload) => {
        const temp = [...state.merchants].filter(m => m.id !== payload.id);
        state.merchants = temp;
        state.totalUnapproved = temp.length;
    }),
    fetchMerchants:Actions.fetchMerchants,
    fetchMerchantsFilter:Actions.fetchMerchantsFilter,
    orderByCompanyName:'',
    selectMerchant:'',
    orderByField:Actions.orderByField,
    onSetApproved: Actions.onSetApproved,
    onDeleteMerchant:Actions.onDeleteMerchant,
    getTotalUnapprovedMerchants:Actions.getTotalUnapprovedMerchants,
}
export default unapprovedMerchantsModel;
