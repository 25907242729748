import {thunk} from "easy-peasy";
import axiosInstance from "../../services/axios";

export const fetchCountries = thunk(async (actions, payload) => {
    actions.setData({key:'fetching', value:true});
     axiosInstance.get('/countries')
        .then(({data})=>{
            const sorted = data.sort((a,b) => a.key.localeCompare(b.key))
            actions.setData({key:'arrCountries', value:sorted})
        }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(() => {
        actions.setData({key:'fetching', value:false});
    })
});
