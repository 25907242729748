import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUser, faArrowRightFromBracket} from '@fortawesome/free-solid-svg-icons';

const HeaderBar = ({logout}) => {
    return <nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0">
        <a className="navbar-brand col-sm-3 col-md-2 mr-0 bg-dark" href="#">
            <h5 className={"d-flex justify-content-center bg-dark"}>DashBoard</h5></a>
        <div className="p-2 me-2">
            <div className="dropdown">
                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" aria-expanded="false">
                    <FontAwesomeIcon color={'white'} icon={faUser}></FontAwesomeIcon>
                    <span className={"ms-2"} style={{color:'white'}}>Hi, Menno</span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{left:'-45px'}}>
                    <li className={"ps-2 my-1"} onClick={logout}>
                        <FontAwesomeIcon icon={faArrowRightFromBracket}/>
                        <span className={"ms-2"}>
                            Logout
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
}
/*
 <button type="button" className="btn btn-sm btn-outline-light" onClick={logout}>Logout</button>
 */
export default HeaderBar;
