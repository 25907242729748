import React, {useCallback, useState} from 'react';
import {LIVE} from "../../commons/configs";
import './login.css'
import { faEye, faEyeSlash, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useStoreActions} from "easy-peasy";
import axiosInstance,{setAxiosToken} from "../../services/axios";

const styles = {
  loginMessage: {
  backgroundColor: '#dc3545',
  color: 'white',
  fontWeight: 'bold',
  padding: '8px 12px',
  borderRadius: 4,
  marginBottom: 24,
  textAlign: 'center'
}
}

const InputPass = ({password,setPassword=() => {}}) => {
  const [eyeIsOpen, setEyeIsOpen] = useState(false);
  const onEyeClick = useCallback(() => {
    setEyeIsOpen(current => {
      const ele = document.getElementById('password');
      ele.type = current ? 'password' : 'text';
      return  !current
    })
  },[])

  return <div>
    <span>Password</span>
    <div className='row mx-0' style={{
      borderRadius:'10px',
      borderStyle:'solid',
      borderWidth:'1px',
      borderColor:'rgba(0,0,0,0.075)'
    }}>
      <div className='col-1 d-flex align-items-center me-0'>
        <div id={'iconEye'} role={'button'} onClick={onEyeClick}>
          <FontAwesomeIcon color={'#000'} icon={eyeIsOpen ? faEye : faEyeSlash}/>
        </div>
      </div>
      <div className='col-11 px-0'>
        <div className="form-group">
          <input value={password}
                 onChange={(event) => setPassword(event.target.value)}
                 type="password"
                 className="form-control form-control-lg border-0 shadow-none p-0"
                 name="password"
                 id="password"
                 required=""
                 autoComplete="new-password"
          />
          <div className="invalid-feedback">Enter your password too!</div>
        </div>
      </div>
    </div>

  </div>
}

const InputEmail = ({username,setUsername=() => {}}) => {

  return <div>
    <span>Username</span>
    <div className='row mx-0' style={{
      borderRadius:'10px',
      borderStyle:'solid',
      borderWidth:'1px',
      borderColor:'rgba(0,0,0,0.075)'
    }}>
      <div className='col-1 d-flex align-items-center me-0'>
        <div id={'iconEye'}>
          <FontAwesomeIcon color={'#000'} icon={faEnvelope}/>
        </div>
      </div>
      <div className='col-11 px-0'>
        <div className="form-group">
          <input value={username}
                 onChange={(event) => setUsername(event.target.value)}
                 type="email"
                 className="form-control form-control-lg border-0 shadow-none p-0"
                 name="email"
                 id="email"
                 required=""/>
          <div className="invalid-feedback">Oops, you missed this one.</div>
        </div>
      </div>
    </div>
  </div>
}

const Login = () => {
  const {setToken} = useStoreActions((actions) => actions.app);
  const [error, setError] = useState(null)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showLoading, setShowLoading] = useState(false)

  const login = useCallback((event) => {
    event.preventDefault()
    if(username !== '' && password !== ''){
      setShowLoading(true)
      axiosInstance.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
      axiosInstance.post('/token',{
        username,password,live:LIVE
      },{}).then(({data}) => {
        if(!data.access_token){
          setError(data.detail)
        }else{
          setAxiosToken(data.access_token);
          setToken(data.access_token);
          localStorage.setItem('access_token',data.access_token)
        }
      }).finally(()=>{
        axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
        setShowLoading(false)
      })
    }
  },[username, password])

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 min-vh-100 d-flex flex-column justify-content-center">
          <div className="row">
            <div className="col-lg-6 col-md-8 mx-auto">
              <div className="card rounded shadow shadow-sm">
                <div className="card-header text-center">
                  <h3 className="mb-0">Welcome to Dashboard</h3>
                </div>
                <div className="card-body">
                  {error &&
                    <p style={styles.loginMessage}>{error}</p>
                  }
                  <form className="form" onSubmit={e => login(e)} action="#" autoComplete="off" id="formLogin" noValidate=""
                        method="POST">
                   <div className="mt-2">
                     <InputEmail username={username} setUsername={setUsername}/>
                   </div>
                   <div className="mt-2">
                     <InputPass password={password} setPassword={setPassword}/>
                   </div>
                    <div className={"d-flex justify-content-center mt-4"}>
                      {showLoading ? <div className="spinner-border text-primary float-right" role="status"/>:
                      <button type="submit"
                              className="btn btn-success w-100 btn-lg"
                              id="btnLogin">
                        <strong>Login</strong>
                      </button>}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
