import React from 'react';
import {createRoot} from 'react-dom/client';
import { StoreProvider } from 'easy-peasy';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {AxiosInterceptor} from "./services/axios";
import store from './redux'
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<AxiosInterceptor><StoreProvider store={store}><App/></StoreProvider></AxiosInterceptor>);
serviceWorker.unregister();



