import React, {useCallback, useEffect} from "react";
import {useParams} from "react-router";
import User from "./User";
import {useStoreActions, useStoreState} from "easy-peasy";

const UserInfo=props=>{
    const {userSelected,error,updating,fetchingUsers} = useStoreState((state) => state.users);
    const {
        setUserDisable,
        setUserEnable,
        fetchUser,
         } = useStoreActions((actions) => actions.users);
    const params = useParams()


    useEffect(()=>{
        if(userSelected === null && Object.keys(params).length > 0){
            fetchUser({filter: {phoneNumber: params.phoneNumber}})
        }
    },[userSelected])

    const _setUserEnable = useCallback((userId)=>{
        setUserEnable({userId})
    },[])

    const _setUserDisable = useCallback((userId)=>{
        setUserDisable({userId})
    },[])

    return (<User fetchingUsers={fetchingUsers}
                  updating={updating}
                  setUserDisable={_setUserDisable}
                  setUserEnable={_setUserEnable}
                  userSelected={userSelected}
    />)
}
export default UserInfo;
