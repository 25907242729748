import React, {useRef, useEffect, useCallback, useState} from 'react'
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus,faSortAlphaDown,faSortAlphaUp} from "@fortawesome/free-solid-svg-icons";
import ColumnResizer from 'column-resizer';
import './styles.css'

const Paginator=props=>{
    const{head,data,paginator,cPage,nElem,handleOnClickBtn,title,btnAction,onChangeNElement, children, displayPaginator = true}=props
    let tableRef = useRef(null);
    const [minWidth, setMinWidth] = useState('800px');

    const _onResize=()=>{
        if(tableRef.current){
            tableRef.current.classList.remove("grip-resizable")
            tableRef.current.classList.remove("grip-padding")
        }
    }
    useEffect(()=>{
        if(data.length === 0)
            return ;
        let tb = document.getElementById('table')
        let resizer = new ColumnResizer(tb,{
                    resizeMode:'overflow'
                });
        resizer.tb.classList.remove("grip-padding")
        resizer.tb.classList.remove("grip-resizable")
        window.addEventListener('resize',_onResize)
        return ()=>{
            window.removeEventListener('resize',_onResize)
        }

    },[data])

    useEffect(()=>{
        if(data.length > 0)
            tableRef.current.classList.remove("grip-resizable")
    },[data])

    const getHead=(obj)=>{
        if(obj.sortable)
            return <th className="text-center" style={{minWidth: '9rem', verticalAlign:'middle'}}>
                <span className="mr-1">{obj.text}</span>
                <button className="btn btn-sm border-0 active" onClick={obj.callback} >
                    <FontAwesomeIcon color={'#FFF'} icon={obj.order === "asc" ? faSortAlphaDown : faSortAlphaUp}/>
                </button>
            </th>
        return <th style={{verticalAlign: "middle"}}><span>{obj.text}</span></th>
    }

    const drawHeader=()=>{
        return (
            <thead className="table-dark-light " style={{backgroundColor:'rgba(0,0,0,0.9)'}}>
            <tr>
            {
                head.map((h,key)=>{
                    if(typeof(h) === "object")
                        return getHead(h)
                    return <th style={{minWidth: '9rem',verticalAlign: "middle", textAlign:'center'}} key={key}><span>{h}</span></th>
                })
            }
            </tr>
            </thead>
        )
    }

    const drawBody=useCallback(()=>{
        return (
            <tbody>
            {
                 data.map(item=>(item))
            }
            </tbody>
        )
    },[data ])

    const drawPages=()=>{
        const nPages = paginator?.pages ?? 0;
        if(nPages === 0)
            return null
        if(nPages <=7){
            return [...new Array(nPages)].map((index,key)=>{
                const c = (key+1)===cPage?'btn-secondary':'btn-outline-secondary'
                return <button
                    style={key === 0 ? {borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0
                    } : key === 7?{borderTopRightRadius: 0,
                        borderBottomRightRadius: 0
                    } : {}}
                    value={key+1} key={key} type="button" className={"btn " +c} onClick={handleOnClickBtn}>{key+1}</button>
            })
        }
        if(cPage < 5){
            let arrBtns = [...new Array(5)].map((index,key)=>{
                const c = (key+1)===cPage?'btn-secondary':'btn-outline-secondary'
                return <button
                    className={"btn " +c}
                    style={key === 0 ? {borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0
                    } : {}}
                    key={key} type="button" onClick={handleOnClickBtn} value={key+1}>{key+1}</button>
            })

            arrBtns.push(<button type="button" className="btn btn-outline-secondary" value={(nPages+5)/2} onClick={handleOnClickBtn}>{'...'}</button>)
            const c = cPage === nPages?"btn-secondary":"btn-outline-secondary"
            arrBtns.push(<button style={{borderTopRightRadius: 0,
                borderBottomRightRadius: 0
            }} type="button" className={"btn "+ c} onClick={handleOnClickBtn} value={nPages}>{nPages}</button>)
            return arrBtns
        }

        if(nPages - cPage > 3){
            let arrBtns = []
            arrBtns.push(<button style={{borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0
            }} type="button" className="btn btn-outline-secondary" onClick={handleOnClickBtn} value={1}>1</button>)
            arrBtns.push(<button type="button" className="btn btn-outline-secondary" value={(cPage+1)/2} onClick={handleOnClickBtn}>{'...'}</button>)

            arrBtns.push(<button type="button" className="btn btn-outline-secondary" onClick={handleOnClickBtn} value={cPage-1}>{cPage-1}</button> )
            arrBtns.push(<button type="button" className="btn btn-secondary" onClick={handleOnClickBtn} value={cPage}>{cPage}</button> )
            arrBtns.push(<button type="button" className="btn btn-outline-secondary" onClick={handleOnClickBtn} value={cPage+1}>{cPage+1}</button> )
            arrBtns.push(<button type="button" className="btn btn-outline-secondary" value={(nPages+cPage+1)/2} onClick={handleOnClickBtn}>{'...'}</button>)
            arrBtns.push(<button style={{borderTopRightRadius: 0,
                borderBottomRightRadius: 0
            }} type="button" className="btn btn-outline-secondary" onClick={handleOnClickBtn} value={nPages}>{nPages}</button>)

            return arrBtns
        }
        let arrBtns = []
        arrBtns.push(<button
            style={{borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0
            }}
            type="button" className="btn btn-outline-secondary" onClick={handleOnClickBtn} value={1}>1</button>)
        arrBtns.push(<button type="button" className="btn btn-outline-secondary" value={(cPage+1)/2} onClick={handleOnClickBtn}>{'...'}</button>)
        let cc = cPage === (nPages-4)?'btn-secondary':'btn-outline-secondary'
        arrBtns.push(<button type="button" className={"btn " + cc} onClick={handleOnClickBtn} value={nPages-4}>{nPages-4}</button> )
        cc = cPage === (nPages-3)?'btn-secondary':'btn-outline-secondary'
        arrBtns.push(<button type="button" className={"btn " + cc} onClick={handleOnClickBtn} value={nPages-3}>{nPages-3}</button> )
        cc = cPage === nPages-2?'btn-secondary':'btn-outline-secondary'
        arrBtns.push(<button type="button" className={"btn " + cc} onClick={handleOnClickBtn} value={nPages-2}>{nPages-2}</button> )
        cc = cPage === nPages-1?'btn-secondary':'btn-outline-secondary'
        arrBtns.push(<button type="button" className={"btn " + cc} onClick={handleOnClickBtn} value={nPages-1}>{nPages-1}</button>)
        cc = cPage === nPages?'btn-secondary':'btn-outline-secondary'
        arrBtns.push(<button
            style={{borderTopRightRadius: 0,
                borderBottomRightRadius: 0
            }}
            type="button" className={"btn " + cc} onClick={handleOnClickBtn} value={nPages}>{nPages}</button>)

        return arrBtns
    }

    const _handleOnClickBtn=evt=>{
        evt.preventDefault()
        let v = parseInt(evt.target.value)
        if(v === cPage)
            return
        if(v > paginator.pages)
            return;
        if(v < 1)
            return;
        handleOnClickBtn(evt)
    }

    useEffect(() => {
        const ele1 = document.getElementById('table-responsive')
        if(ele1){
            const tr = window.getComputedStyle(ele1);
            setMinWidth(tr.getPropertyValue('width'))
        }
    }, []);

    const drawPaginator=()=>{

        if(paginator === undefined || paginator === null)
            return null

        const {total}=paginator
        const nPages = paginator.pages
        return ( <div className="d-flex justify-content-between">
            <div className="col-2 d-flex flex-row align-items-center">
                    <div className="me-2">
                        <span># Elem:</span>
                    </div>
                    <div>
                        <select id={'selectElements'}
                                style={{width:'70px'}}
                                className="form-select form-select-sm"
                                aria-label="items by page"
                                onChange={evt => onChangeNElement(parseInt(evt.target.value))}
                        >
                            <option selected = {nElem === 10} value={10}>10</option>
                            <option selected = {nElem === 15} value={15}>15</option>
                            <option selected = {nElem === 20} value={20}>20</option>
                            <option selected = {nElem === 30} value={30}>30</option>
                            <option selected = {nElem === 50} value={50}>50</option>
                        </select>
                    </div>
            </div>
            <div className="col-2 d-flex flex-row align-items-center">
                    <div className="d-flex me-2">
                        <span>Page #:</span>
                    </div>
                    <div>
                        <select id={'nPage'}
                                style={{width:'70px'}}
                                className="form-select form-select-sm"
                                aria-label="page selected"
                                onChange={handleOnClickBtn}
                        >
                            {
                                [...new Array(paginator.pages)].map((_,index) => <option selected = {(index+1) === cPage} value={index+1}>{index+1}</option>)
                            }
                        </select>
                    </div>
            </div>
            <div className={"col-8 d-flex flex-row justify-content-end"}>
                <div className="d-flex align-items-center me-2">
                    <span>{"Display " + ((cPage-1)*nElem +1)  + " to " +cPage*nElem+ " of: " +total}</span>
                </div>
                <div className="float-right">
                    <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                        <div className="btn-group btn-group-sm mr-2" role="group" aria-label="First group">
                            <button
                                type="button"
                                className="btn btn-outline-secondary"
                                value={1}
                                onClick={_handleOnClickBtn}
                            >&laquo;</button>
                            <button
                                style={{borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0
                                }}
                                type="button" className="btn btn-outline-secondary" value={cPage-1} onClick={_handleOnClickBtn}>&lsaquo;</button>

                        </div>
                        <div className="btn-group btn-group-sm mr-2" role="group" aria-label="Second group">
                            {
                                drawPages()
                            }
                        </div>
                        <div className="btn-group btn-group-sm" role="group" aria-label="Third group">
                            <button
                                style={{borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0
                                }}
                                type="button" className="btn btn-outline-secondary" value={cPage+1} onClick={_handleOnClickBtn}>&rsaquo;</button>
                            <button type="button" className="btn btn-outline-secondary" value={nPages} onClick={_handleOnClickBtn}>&raquo;</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }

    return (
       <div className={"container p-0"}>
           {(title || btnAction) && <div className={"d-flex " + (title || btnAction ? "justify-content-between" : "justify-content-end")}>
               {
                   title && <div className="p-2 bd-highlight"><h3>{title}</h3></div>
               }
               {
                   btnAction && <div className="p-2 bd-highlight">
                       <button type="button" className="btn btn-outline-secondary" onClick={btnAction.callback}>
                           <FontAwesomeIcon color={'#007BFF'} icon={faPlus}/>
                           <span className="ml-1">{btnAction.text}</span>
                       </button>
                   </div>
               }
           </div>}
           <div className={"card border-0 shadow-lg"}>
           <div className={"card-header"}>
               <div className="d-flex justify-content-between">
                   {children}
               </div>
           </div>
           <div className={'card-body'}>
                   {
                       data.length === 0 &&  <div className="py-2 text-center">
                           <h1>No data to display</h1>
                       </div>
                   }
                   {
                       data.length > 0 &&  <div id={"table-responsive"} className="table-responsive"  style={{minHeight:'400px'}}> <table
                           id={"table"}
                           ref={tableRef}
                           className="table table-borderless table-striped table-hover table-sm text-center align-middle mb-0"
                           style={{minWidth:minWidth}}
                       >
                           {drawHeader()}
                           {drawBody()}
                       </table></div>
                   }
           </div>

           {
               displayPaginator && data.length > 0 && <div className={'card-footer'}>
                   {drawPaginator()}
               </div>
           }
       </div></div>

    )

}

Paginator.propTypes={
    head:PropTypes.array.isRequired,
    data:PropTypes.array.isRequired,
    paginator:PropTypes.object.isRequired,
    cPage:PropTypes.number.isRequired,
    nElem:PropTypes.number.isRequired,
    handleOnClickBtn:PropTypes.func.isRequired
}

export default Paginator
