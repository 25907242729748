import React, {useEffect, useMemo} from "react";
import { useLocation, useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faUsers,
    faUsersCog,
    faFilter,
    faBoxes,
    faImages,
    faCity,
    faPlus,
    faCartShopping,
    faBell
} from '@fortawesome/free-solid-svg-icons'
import './style.css'
import {useStoreActions, useStoreState} from "easy-peasy";

const Menu = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {totalUnapproved= 0} = useStoreState((state) => state.unapprovedMerchants);
    const {getTotalUnapprovedMerchants} = useStoreActions((actions) => actions.unapprovedMerchants);

    useEffect(() => {
        getTotalUnapprovedMerchants && getTotalUnapprovedMerchants();
    }, [getTotalUnapprovedMerchants])

    const menuItems = useMemo(() => ([
        {title:'Merchants',icon:faUsersCog,route:'/merchants',pathname:'/merchants'},
        {title:`Unapproved (${totalUnapproved})`,icon:faUsersCog,route:'/merchants-unapproved',pathname:'/merchants-unapproved'},
        {title:'Products',icon:faBoxes,route:'/products',pathname:'/products'},
        {title:'Users',icon:faUsers,route:'/users',pathname:'/users'},
        {title:'Categories',icon:faFilter,route:'/categories',pathname:'/categories'},
        {title:'Countries',icon:faCity,route:'/countries',pathname:'/countries'},
        {title:'Photos',icon:faImages,route:'/photos',pathname:'/photos'},
        {title:'Orders',icon:faCartShopping,route:'/orders',pathname:'/orders'},
        {title:'Subscriptions',icon:faBell,route:'/subscriptions',pathname:'/subscriptions'},
        {title:'Add Photos',icon:faPlus,route:'/add-photos',pathname:'/add-photos'},
        {title:'Add Merchant',icon:faPlus,route:'/merchants/create',pathname:'/merchants/create'},

    ]),[totalUnapproved])

    return (
        <nav className="navbar sidebar-sticky bg-dark align-items-start">
            <ul className="list-group w-100">{
                menuItems.map((item,index) => {
                    const txtColor = location.pathname === item.pathname ? 'rgb(255, 193, 7)' : '#FFF'
                    return(
                        <li key={`menu-${index}`} className={"list-group-item bg-dark border-0"}
                            onClick={() => navigate(item.route)}
                            style={{cursor:'pointer'}}
                        >
                            <div className={"d-flex justify-content-start align-items-center ps-4 mt-2"}>
                                <FontAwesomeIcon color={txtColor} icon={item.icon}/>
                                <span className={"ms-2"} style={{color: txtColor}}>{item.title}</span>
                            </div>
                        </li>
                    );
                })
            }
            </ul>
        </nav>
    )
}

export default Menu
