import {thunk} from "easy-peasy";
import axiosInstance from "../../services/axios";

export const fetchUsers = thunk(async (actions, payload) => {
    actions.setData({key:'fetchingUsers',value:true});
    let url = `/users?page=${payload.cPage}&elements=${payload.nElem}&computeTotal=true&sortByCreatedDesc=true`
    axiosInstance.get(url).then(({data})=>{
        if(Array.isArray(data)) {
            actions.setData({key: 'arrUsers', value: data})
        }else{
            const {users, total} = data;
            actions.setData({key: 'arrUsers', value: users});
            actions.setData({key: 'pagination', value: {pages: Math.ceil(total / payload.nElem), elements: payload.nElem,total}});
        }
    }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(()=> {
        actions.setData({key:'fetchingUsers',value:false});
    })

});

export const setUserEnable = thunk(async (actions, payload) => {
    actions.setData({key:'updating',value:true});
    axiosInstance.put('/users/enable',{
        userId:payload.userId,
        enabled:payload.enabled
    })
        .then(({data})=>{
            actions.update(data)
        }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(()=> {
        actions.setData({key:'updating',value:false})
    })
});

export const fetchUser = thunk(async (actions, payload) => {
    actions.setData({key:'fetchingUsers',value:true});
    let url = '/users?page=1&elements=100&computeTotal=false';
    Object.keys(payload.filter).forEach(key => {
        if(key === 'phoneNumber'){
            url += `&${key}=%2B${payload.filter[key].substring(1)}`;
        }else{
            url += `&${key}=${payload.filter[key]}`;
        }

    })
    axiosInstance.get(url)
        .then(({data})=>{
            actions.setData({key:'userSelected', value:data[0]})
        }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(()=> {
        actions.setData({key:'fetchingUsers',value:false})
    })
});
