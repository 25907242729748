import React, {useCallback, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEnvelope,faKey, faEye, faEyeSlash, faSave} from '@fortawesome/free-solid-svg-icons'
const checkStrongPass = (password) => {

        let score = 0;

        if(password.match(/[a-z]/)){
            score += 20;

        }
    if( password.match(/[A-Z]/)){
        score += 20;

    }
    if ( password.match(/[0-9]/)) {

        score += 20;
    }
    if(password.match(/[^a-zA-Z\d]/)){
        score += 20;

    }
        if (password.length > 8) {
            score += 20;
        }

    return score
};
const FormMerchant = ({onSave}) => {
   const [data, setData] = useState({email:'',password:'',rePassword:''});
   const [passVisible, setPassVisible] = useState(false);
   const [fieldErrors, setFieldErrors] = useState({email:false,password:false, rePassword:false, form:false});
   const [passStrength, setPassStrength] = useState(0);
   const handleSubmit = useCallback((evt)=>{
         evt.preventDefault();
         let flagError = false;
         //valid data
       if(passStrength < 60){
           return;
       }
       const exp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
       const fieldsValidation = {email:false,password:false, rePassword:false, form:false}
       if (!exp.test(data.email)){
            fieldsValidation.email = true;
            flagError = true;
       }
       if(!data.password && checkStrongPass(data.password) < 75){
           fieldsValidation.password = true;
           flagError = true;
       }

       if(!data.rePassword || data.rePassword !== data.password){
           fieldsValidation.rePassword = true;
           flagError = true;
       }
       setFieldErrors({...fieldsValidation, form:flagError});
       if(flagError){
           return;
       }
       onSave(data);
   },[data,onSave,passStrength]);

   console.log('fieldErrors',fieldErrors);
    console.log('data',data);
    return (
        <div className="card">
            <div className="card-header">
                New Merchant
            </div>
            <div className="card-body">
                <form className={`row g-3`} noValidate>
                    <div className='mb-1'>
                        <label htmlFor="email" className="form-label">Email</label>
                        <div className="input-group flex-nowrap ">
                            <span className="input-group-text" id="email"><FontAwesomeIcon icon={faEnvelope}/></span>
                            <input type="email"
                                   required={true}
                                   id={'email'}
                                   name={'email'}
                                   className={`form-control ${fieldErrors.email ? 'is-invalid' : ''}`}
                                   placeholder="name@example.com"
                                   aria-label="Email"
                                   aria-describedby="addon-wrapping"
                                   onChange={(evt) => {
                                       setFieldErrors({...fieldErrors, email: false});
                                       setData({...data, email: evt.target.value})
                                   }}
                            />
                        </div>

                        <div className={`invalid-feedback ${fieldErrors.email ? 'd-block' : ''}`}>
                            Incorrect email format.
                        </div>
                    </div>

                    <div>
                        <label htmlFor="password" className="form-label">Password</label>
                        <div className="input-group flex-nowrap">
                        <span className="input-group-text" id="email">
                            <FontAwesomeIcon icon={faKey}/>
                        </span>
                            <input type={passVisible ? 'text' : 'password'}
                                   required={true}
                                   id={'password'}
                                   name={'password'}
                                   className={`form-control ${fieldErrors.password ? 'is-invalid' : ''}`}
                                   placeholder="********"
                                   aria-label="Password"
                                   aria-describedby="addon-wrapping"
                                   onChange={(evt) => {
                                      setPassStrength( checkStrongPass(evt.target.value));
                                       setFieldErrors({...fieldErrors, password: false, rePassword: false});
                                       setData({...data, password: evt.target.value})
                                   }}
                                   style={{outline:"none !important"}}
                            />
                            <button className="btn btn-outline-secondary"
                                    type="button" id="button-addon2"
                                    onClick={() => setPassVisible(!passVisible)}
                            >
                                <FontAwesomeIcon icon={passVisible ? faEyeSlash : faEye}/>
                            </button>
                        </div>
                        {(!!passStrength && !fieldErrors.password) &&
                            <div className="progress mt-2">
                                {passStrength > 0 && <div className="progress-bar bg-danger progress-bar-striped progress-bar-animated"
                                      role="progressbar"
                                      style={{width: "20%"}}
                                >very weak</div>}
                                {(passStrength > 20) && <div className="progress-bar bg-warning progress-bar-striped progress-bar-animated"
                                      role="progressbar"
                                      style={{width: "20%"}}
                                >weak</div>}
                                {(passStrength > 40) && <div className="progress-bar bg-info progress-bar-striped progress-bar-animated"
                                                             role="progressbar"
                                                             style={{width: "20%"}}
                                >medium</div>}
                                {(passStrength > 60) &&<div className="progress-bar progress-bar-striped progress-bar-animated"
                                      role="progressbar"
                                      style={{width: "20%"}}
                                >strong</div>}
                                {(passStrength > 80) && <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"
                                      role="progressbar"
                                      style={{width: "20%"}}
                                >very strong</div>}
                        </div>

                        }
                        <div className={`invalid-feedback ${fieldErrors.password ? 'd-block' : ''}`}>
                            Please choose a password.
                        </div>
                    </div>
                    <div className={'mb-3'}>
                        <label htmlFor="rePassword" className="form-label">Confirm Password</label>
                        <div className="input-group flex-nowrap">
                        <span className="input-group-text" id="email">
                            <FontAwesomeIcon icon={faKey}/>
                        </span>
                            <input type={passVisible ? 'text' : 'password'}
                                   required={true}
                                   id={'rePassword'}
                                   name={'rePassword'}
                                   className={`form-control ${fieldErrors.rePassword ? 'is-invalid' : ''}`}
                                   placeholder="********"
                                   aria-label="Password"
                                   aria-describedby="addon-wrapping"
                                   onChange={(evt) => {
                                       setFieldErrors({...fieldErrors, password: false,rePassword:false})
                                       setData({...data, rePassword: evt.target.value})
                                   }}/>
                            <button className="btn btn-outline-secondary"
                                    type="button" id="button-addon2"
                                    onClick={() => setPassVisible(!passVisible)}
                            >
                                <FontAwesomeIcon icon={passVisible ? faEyeSlash : faEye}/>
                            </button>
                        </div>
                        <div className={`invalid-feedback ${fieldErrors.rePassword ? 'd-block' : ''}`}>
                            not match.
                        </div>
                    </div>
                </form>
            </div>
            <div className="card-footer text-muted d-flex justify-content-end">
                <a href="#" className="btn btn-primary" onClick={handleSubmit}>
                    <FontAwesomeIcon icon={faSave}/>
                    <span className={'ms-2'}>Save</span>
                </a>
            </div>
        </div>
    );
}
export default FormMerchant;
