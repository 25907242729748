import React, {useState, useEffect, useRef, useCallback, useMemo} from "react";
import {N_ELEMENT_BY_PAGE} from "../../commons/configs";
import { faSearch} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Merchant from "./views/Merchant";
import {useStoreActions, useStoreState} from "easy-peasy";
import { LoadingModal, ConfirmModal, FilterCountry, Paginator} from "../../commons";

const UnapprovedMerchants = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [cPage,setCPage]=useState(1)
  const [filter,setFilter]=useState(null)
  const [code,setCode]=useState('ALL')
  const [displayConfirm,setDisplayConfirm]=useState(false)
  const [merchantIdSelected,setMerchantIdSelected]=useState(null)
  const [arrMerchants,setArrMerchants]=useState([])
  const [order,setOrder]=useState({
    sortByName:null,
    sortBySlug:null,
    sortByEmail:null,
    sortByCompanyName:null,
    sortByPriority:null,
    sortByCreated:'desc'
  });

  const [nElem,setNElem]=useState(N_ELEMENT_BY_PAGE);
  const lastNElement = useRef(N_ELEMENT_BY_PAGE);

  const refFormSearch = useRef(null)
  const refFormInput = useRef(null)

  const {
    error,
    isFetching,
    paginator,
    merchants,
    publishing
  } = useStoreState((state) => state.unapprovedMerchants);
  const {totalUnapproved} = useStoreState((state) => state.merchant);
  const {
      fetchMerchantsFilter,
      onSetApproved,
    onDeleteMerchant
} = useStoreActions((actions) => actions.unapprovedMerchants);

const {setData} = useStoreActions((actions) => actions.merchant);

  useEffect(() => {
    fetchMerchantsFilter({nElem, cPage, filter,order});
  },[order])

  const onChangeOrder = useCallback((field)=>{
    setOrder(current => {
      const temp = {...current};
      Object.keys(temp).forEach(key => {
        if(key !== field){
          temp[key] = null;
        }else {
          temp[key] = temp[key] === null ? "desc" : temp[key] === 'desc' ? "asc" : "desc";
        }
      })
      return temp;
    });
  },[]);

  const tHead= useMemo(() =>(  [
    {text: "Company",sortable:true,order:order.sortByCompanyName,callback:() => onChangeOrder('sortByCompanyName')},
    {text: "Slug",sortable:true,order:order.sortBySlug,callback:() => onChangeOrder('sortBySlug')},
    "Address","Postcode",
    {text: "Email",sortable:true,order:order.sortByEmail,callback:() => onChangeOrder('sortByEmail')},
    {text: "Name",sortable:true,order:order.sortByName,callback:() => onChangeOrder('sortByName')},
    "Prod Count","Open Hour","Cover photo","P.Web","P.Map","P. App","P.Payment","Suggested","Sales",
    {text:"Created",sortable: true,order:order.sortByCreated, callback:() => onChangeOrder('sortByCreated') },
    "Actions"]),[order,onChangeOrder]);

  useEffect(()=>{
    if(nElem !== lastNElement.current){
      fetchMerchantsFilter({nElem, cPage:1, filter,order});
      lastNElement.current = nElem;
    }
  },[nElem])


  const deleteMerchant = useCallback((merchantId)=>{
    setDisplayConfirm(true)
    setMerchantIdSelected(merchantId)
  },[])

  const _onAcceptDelete = useCallback(()=>{
    setDisplayConfirm(false)
    onDeleteMerchant({merchantId:merchantIdSelected})
  },[onDeleteMerchant,merchantIdSelected])

  const selectMerchant = useCallback((merchant)=>{
    setData({key:'merchantSelected',value:merchant})
  },[setData])

  const setApproved = useCallback((merchantId,approved, nElem, nPage)=>{
    onSetApproved({merchantId, value:approved, urlKey:'approved', field:'approved'})
  },[onSetApproved])

    const formatMerchants = useCallback(()=>{
        if(merchants.length > 0){
            let m = merchants.map((ele)=>(<Merchant
                    key={ele.id}
                    data={ele}
                    setApproved={setApproved}
                    deleteMerchant={deleteMerchant}
                    selectMerchant={selectMerchant}
                    loginOnMerchant={() => {}}
                    nElem={nElem}
                    nPage={cPage}
                />
            ))
            setArrMerchants(m)
        }else{
          setArrMerchants([]);
        }
    },[merchants,selectMerchant,deleteMerchant,nElem,cPage,setApproved])

    useEffect(()=>{
      formatMerchants();
    },[merchants])

  const handleOnClickBtn = useCallback((evt)=>{
    evt.stopPropagation()
    const cp = parseInt(evt.target.value);
    fetchMerchantsFilter({nElem, cPage:cp, filter,order})
    setCPage(cp)
  },[nElem,fetchMerchantsFilter,filter,order])

  const _handleCountry = useCallback((code)=>{
    let f = {...filter,"computeProductSummary":true}
    if(code === 'ALL'){
      delete f.country;
    }else {
      f={...f,country:code};
    }
    fetchMerchantsFilter({nElem, cPage, filter:f,order})
    setCode(code)
    setFilter(f)
  },[nElem, cPage, filter,order,fetchMerchantsFilter])

  const onChangeNElement=nElements=>{
    setNElem(nElements)
  }
/*
  const onChangeInputSearch = () =>{
    refFormSearch.current.classList.remove('was-validated')
  }
  */
  const onChangeInputSearch = useCallback(evt => {
    setSearchTerm(evt.target.value)
  },[])
  const onSubmitSearchMerchant = useCallback(evt => {
    evt.preventDefault();
    evt.stopPropagation();
    fetchMerchantsFilter({nElem,cPage: 1, filter:!searchTerm ? null : {queryCompany: searchTerm},order})
    setFilter(current => {
      let f = {...current};
      if(!searchTerm){
        delete f.queryCompany;
      }else{
        f = {...f, queryCompany: searchTerm}
      }
      return f;
    })

  },[searchTerm,order,fetchMerchantsFilter, nElem])

  const subHeader=()=>{
    return (<div className="row w-100">
      <div className="col-4">
        <form ref={refFormSearch} onSubmit={onSubmitSearchMerchant} className="form-inline needs-validation" noValidate>
          <div className="input-group input-group-sm w-100">
            <input onChange={onChangeInputSearch} ref={refFormInput} type="text" className="form-control" placeholder="Search unapproved merchants "/>
            <div className="input-group-append ">
              <button className="btn btn-secondary btn-sm" type="submit" style={{borderTopRightRadius: '.25rem', borderBottomRightRadius: '.25rem'}}>
                <FontAwesomeIcon icon={faSearch}/>
              </button>
            </div>
            <div className="invalid-feedback">
              Please provide a Merchant´s name.
            </div>
          </div>
        </form></div>
    </div>)
  }

  return (
      <>
        {(isFetching || publishing) && <LoadingModal isLoading={true}/>}
        <ConfirmModal display={displayConfirm}
                      title={"Confirm!"}
                      text={"Are you sure you want to delete this Merchant?"}
                      _onCancel={()=>setDisplayConfirm(false)}
                      _onAccept={_onAcceptDelete}
        />
        <FilterCountry code={code} title={"Unapproved merchants"} handleCountry={_handleCountry}/>
        {
            error && <div className="alert alert-danger" role="alert">
              Error MSG
            </div>
        }
        <Paginator
            cPage={cPage}
            data={arrMerchants}
            head={tHead}
            nElem={nElem}
            handleOnClickBtn={handleOnClickBtn}
            onChangeNElement={onChangeNElement}
            paginator={paginator}
        >
          {
            subHeader()
          }
        </Paginator>
      </>
  )

}
export default UnapprovedMerchants
