import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCog,faCheck,faBan } from '@fortawesome/free-solid-svg-icons'
import {LoadingModal} from "../../../commons";

const User=({userSelected,updating,fetchingUsers,setUserEnable,setUserDisable})=>{

    const _setUserEnable=(evt)=>{
        evt.stopPropagation()
        setUserEnable(userSelected.id)
    }
    const _setUserDisable=(evt)=>{
        evt.stopPropagation()
        setUserDisable(userSelected.id)
    }

    return (<div className={"container"}>
        {
            (!userSelected || updating || fetchingUsers) && <LoadingModal isLoading={true} />
        }
            <h3>User info</h3>
        {
            userSelected && <div className={"col-6"}>
                <div className="card shadow-lg border-0">
                    <div className="card-body">
                        <div className={'row'}>
                            <div className={'col-7'}>
                                <span className={"fw-bold me-2"}>Phone number:</span>
                                <span className={"fw-normal"}>{userSelected.phoneNumber}</span>
                            </div>
                            <div className={'col-5'}>
                                <span className={"fw-bold me-2"}>CurrentOs:</span>
                                <span className={"fw-normal"}>{userSelected.currentOs}</span>
                            </div>
                        </div>
                        <div className={'row mt-2'}>
                            <div className={'col-7'}>
                                <span className={"fw-bold me-2"}>Current Device:</span>
                                <span className={"fw-normal"}>{userSelected.currentDevice ?? '-'}</span>
                            </div>
                            <div className={'col-5'}>
                                <span className={"fw-bold me-2"}>Current Os Version:</span>
                                <span className={"fw-normal"}>{userSelected.currentOsVersion ?? '-'}</span>
                            </div>
                        </div>
                        <div className={'row mt-2'}>
                            <div className={'col-7'}>
                                <span className={"fw-bold me-2"}>Created:</span>
                                <span className={"fw-normal"}>{moment(userSelected.created).format('YYYY/MM/DD')}</span>
                            </div>
                            <div className={'col-5'}>
                                <span className={"fw-bold me-2"}>Updated:</span>
                                <span className={"fw-normal"}>{ userSelected.modified ? moment(userSelected.modified).format('YYYY/MM/DD') : '-'}</span>
                            </div>
                        </div>
                        <div className={'row mt-2'}>
                            <div className={'col-7'}>
                                <span className={"fw-bold me-2"}>Phone Confirmed</span>
                                <span className={"fw-normal"}>{userSelected.phoneConfirmed ? 'Yes' : 'No'}</span>
                            </div>
                            <div className={'col-5'}>
                                <span className={"fw-bold me-2"}>Disabled:</span>
                                <span className={"fw-normal"}>{userSelected.disabled ? 'Yes' : 'No'}</span>
                            </div>
                        </div>

                        <div className={'row mt-4'}>
                            <div className={'col-7'}></div>
                            <div className={'col-5'}>
                                {
                                        userSelected?.disabled ?<button type="button" className="btn btn-outline-success btn-sm w-100" onClick={_setUserEnable}>
                                                <FontAwesomeIcon color={'green'} icon={faCog}></FontAwesomeIcon>
                                                <span>{' Allow'}</span>
                                            </button>:
                                            <button type="button" className="btn btn-outline-danger btn-sm w-100" onClick={_setUserDisable}>
                                                <FontAwesomeIcon color={'red'} icon={faBan}></FontAwesomeIcon>
                                                <span>{' Deny'}</span>
                                            </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        <br/>
        <h3>Confirmations</h3>
        {
            userSelected && <div>
                <div className={'card shadow-lg border-0'}>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table
                                className="table table-borderless table-striped table-hover table-sm text-center align-middle mb-0"
                            >
                                <thead className="table-dark-light " style={{backgroundColor:'rgba(0,0,0,0.9)'}}>
                                <tr>
                                    <th>Phone Number</th>
                                    <th>OS</th>
                                    <th>OS Version</th>
                                    <th>Device</th>
                                    <th>Modified</th>
                                    <th>Created</th>
                                    <th># Twilio SMS</th>
                                    <th>Confirmed</th>
                                    <th>Access</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="align-middle">{userSelected.phoneNumber}</td>
                                    <td className="align-middle">{userSelected.currentOs}</td>
                                    <td className="align-middle">{userSelected.currentOsVersion}</td>
                                    <td className="align-middle">{userSelected.currentDevice}</td>
                                    <td className="align-middle">{moment(userSelected.modified).format("YYYY/MM/DD HH:mm")}</td>
                                    <td className="align-middle">{moment(userSelected.created).format("YYYY/MM/DD HH:mm")}</td>
                                    <td className="align-middle text-center">{userSelected.confirmationCodes !== null? userSelected.confirmationCodes.length:0}</td>
                                    <td className="align-middle text-center">{userSelected.phoneConfirmed===false?<FontAwesomeIcon color={'red'} icon={faBan}/>: <FontAwesomeIcon color={'green'} icon={faCheck}/>}</td>
                                    <td className="align-middle">
                                        {
                                            userSelected.disabled?<button type="button" className="btn btn-sm btn-outline-success w-100" onClick={_setUserEnable}>
                                                    <FontAwesomeIcon color={'green'} icon={faCog}></FontAwesomeIcon>
                                                    <span>{' Allow'}</span>
                                                </button>:
                                                <button type="button" className="btn btn-sm btn-outline-danger w-100" onClick={_setUserDisable}>
                                                    <FontAwesomeIcon color={'red'} icon={faBan}></FontAwesomeIcon>
                                                    <span>{' Deny'}</span>
                                                </button>
                                        }
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>)
}
export default User
