import {action} from "easy-peasy";
import * as Actions from './actions';

const ordersModel = {
    fetchingPagination:false,
    pagination:{pages: 0, elements: 0,total: 0},
    error:null,
    fetchingOrders:false,
    arrOrders:[],
    updating:false,
    setData:action((state,payload) => {
        state[payload.key] = payload.value
    }),
    fetchOrders:Actions.fetchOrders
};
export default ordersModel;
