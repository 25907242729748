import { action, thunk } from 'easy-peasy';
import * as Actions from './actions'

const productModel = {
    error:null,
    paginator:{pages: 0, elements: 0,total: 0},
    products:[],
    isFetching:false,
    deleting:false,
    publishingProduct:false,
    update:action((state, payload) => {
        state.products = [...state.products].map(ele => ele.id === payload.id ? {...payload} : ele);
    }),
    remove:action((state, payload) => {
        state.products = [...state.products].filter(p => p.id !== payload.productId);
    }),
    setData:action((state,payload) => {
        state[payload.key] = payload.value
    }),
    fetchProducts:Actions.fetchProducts,
    deleteProduct:Actions.deleteProduct,
    setPublish:Actions.setPublish
}

export default productModel
