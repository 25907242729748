import {action} from "easy-peasy";
import * as Actions from './actions'

const countriesModel = {
    error:null,
    arrCountries:[],
    fetching:false,
    setData:action((state,payload) => {
        state[payload.key] = payload.value
    }),
    fetchCountries: Actions.fetchCountries
};
export default countriesModel;
