import React, {useState, useEffect, useCallback} from "react";
import {FilterCountry, LoadingModal, Paginator} from "../../commons";
import {N_ELEMENT_BY_PAGE} from "../../commons/configs";
import CategoryRow from "./views/CategoryRow";
import {useStoreActions, useStoreState} from "easy-peasy";
const Categories=(props)=>{
    const {pagination,fetchingCategories,error,categories,fetchingPagination,publisching} = useStoreState((state) => state.categories);
    const {
         fetchCategories,
        onPublish,
    } = useStoreActions((actions) => actions.categories);
    const [dataCat,setDataCat]=useState([])
    const [code,setCode]=useState('ALL')
    const [cPage,setCPage]=useState(1)
    const [filter,setFilter]=useState(null)
    const [nElem,setNElem]=useState(N_ELEMENT_BY_PAGE)
    const tHead = ["Region","Key","Title","Publish","Num Products",	"Action"]

    useEffect(()=>{
        fetchCategories({nElem, cPage:1});
    },[])

    useEffect(()=>{
        if(nElem !== N_ELEMENT_BY_PAGE){
        fetchCategories({nElem, cPage:1, filter})
        }
    },[nElem, filter])

    const setPublish = useCallback((id,publish)=>{
        onPublish({categoryId:id,publish})
    },[onPublish])

    const formatCategories = useCallback((cat)=>{
        if(cat !== undefined){
            let temp = cat.map((c,k)=>(<CategoryRow
                setPublish={setPublish}
                key={k}
                data={c}
            />))
            setDataCat(temp)
        }
    },[setPublish])

    useEffect(()=>{
        formatCategories(categories)
    },[categories])



    const _handleCountry = useCallback((code)=>{
        const f = code === 'ALL'? null : {"region":code};
        fetchCategories({nElem, cPage:1,filter: f});
        setFilter(f)
        setCode(code)
    },[filter])

    const handleCPage = useCallback((evt)=>{
        evt.stopPropagation()
        let p = parseInt(evt.target.value)
        fetchCategories({nElem, cPage:p, filter})
        setCPage(p)
    },[nElem,filter])

    const onChangeNElement  = useCallback(nElements=>{
        setNElem(nElements)
    },[])

    const display=()=>{
        if(error)
            return (<>
                <h1>Categories</h1>
                <div className="alert alert-danger" role="alert">
                    Error MSG
                </div>
            </>
            )
        if(fetchingPagination||fetchingCategories||publisching){
            return( <>
                <FilterCountry code={code}  title={"Categories"} handleCountry={_handleCountry}/>
                <LoadingModal isLoading={fetchingPagination || fetchingCategories || publisching} />
                {
                    dataCat.length > 0 && <Paginator
                        cPage={cPage}
                        data={dataCat}
                        head={tHead}
                        nElem={nElem}
                        handleOnClickBtn={handleCPage}
                        paginator={pagination}
                    />
                }

            </>)
        }

        return( <>
            <FilterCountry code={code}   title={"Categories"} handleCountry={_handleCountry}/>
            {
                dataCat.length > 0 ? <Paginator
                    cPage={cPage}
                    data={dataCat}
                    head={tHead}
                    nElem={nElem}
                    handleOnClickBtn={handleCPage}
                    paginator={pagination}
                    onChangeNElement={onChangeNElement}
                />:<div className="alert alert-danger" role="alert">
                    No Categories to show!!
                </div>
            }

        </>)
    }
    return (display())
}
export default Categories
