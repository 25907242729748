import React,{useEffect} from "react";
import {useStoreActions, useStoreState} from "easy-peasy";
import { LoadingModal} from "../../commons";
import {CountryRow} from "./views/CountryRow";

const Countries = () =>{
    const {error,arrCountries,fetching}= useStoreState((state) => state.countries);
    const {fetchCountries} =  useStoreActions((actions) => actions.countries);

    useEffect(()=>{
        fetchCountries()
    },[])

    const display=()=>{
        if(error)
            return (<>
                <h1>Countries</h1>
                <div className="alert alert-danger" role="alert">
                    Error MSG
                </div>
            </>)
        if(fetching)
            return( <>
            <LoadingModal isLoading={fetching} />
        </>)
        return(
            <>
                {
                    arrCountries.length > 0 && (
                        <div className="table-responsive">
                            <div className="d-flex justify-content-start border-bottom mb-4">
                                <div className="p-2 bd-highlight"><h3>Countries</h3></div>
                            </div>

                            <table className="table table-striped table-sm text-center align-middle">
                                <thead className="table-dark-light ">
                                <tr>
                                    <th>Name</th>
                                    <th style={{minWidth:'50px'}}>Flag</th>
                                    <th style={{minWidth:'50px'}}>ISO</th>
                                    <th style={{minWidth:'50px', maxWidth:'50px'}}>Country Code</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    arrCountries.map((c,k)=>(<CountryRow key={k} data={c}/>))
                                }
                                </tbody>
                            </table></div>
                    )
                }
            </>
        )
    }
    return display()
}

export default Countries
