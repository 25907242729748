import React, {useEffect, useState} from 'react';
import {useStoreActions, useStoreState} from "easy-peasy";
import {LoadingModal, Paginator} from "../../commons";
import SubscriptionRow from "./view/SubscriptionRow";

const tHeader = ['Name','Nickname', 'Email','Status', 'Canceled At', 'Cancel At', 'Cancel At Period End', 'Period Start', 'Period End', 'Plan ID', 'Amount', 'Interval', 'Disabled', 'Created', 'Modified', 'Email Confirmed', 'Phone Number' ,'Phone Confirmed'];
const Subscriptions = () => {
    const [arrSubscriptions, setArrSubscriptions] = useState([]);
    const {isLoading, error, subscriptions} = useStoreState((state) => state.subscriptions);
    const {fetchSubscriptions} = useStoreActions((actions) => actions.subscriptions);

    useEffect(() => {
        fetchSubscriptions && fetchSubscriptions();
    }, [fetchSubscriptions]);

    useEffect(() => {
        setArrSubscriptions(subscriptions.map((item,index) => (<SubscriptionRow key={index} subscription={item}/>)));
    },[subscriptions]);

    return (<>
            <div>
                <h1>Subscriptions</h1>
            </div>
            {
                error && <div className="alert alert-danger" role="alert">
                    Error MSG {error}
                </div>
            }
            <LoadingModal isLoading={isLoading}/>
            <Paginator
                cPage={1}
                data={arrSubscriptions}
                head={tHeader}
                nElem={50}
                handleOnClickBtn={() => {}}
                onChangeNElement={() => {}}
                displayPaginator={false}
                paginator={{}}
        />
        </>
    );
}
export default Subscriptions;
