import { action} from 'easy-peasy';

const appModel = {
    user:'',
    access_token:localStorage.getItem('access_token'),
    setToken:action((state, payload) => {
        state.access_token = payload;
    }),
    setUser:action((state, payload) => {
        state.user = payload;
    })
}

export default appModel
