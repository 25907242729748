import React, {useCallback, useState} from 'react';
import FormMerchant  from "./views/FormMerchant";
import {useStoreActions, useStoreState} from "easy-peasy";
import {LoadingModal} from "../../commons";
const AddMerchant = () => {
    const {
        createMerchant,
        setData
    } = useStoreActions((actions) => actions.merchant);

    const {
        isSaving,
        merchantCreated,
        createMerchantError
    } = useStoreState((state) => state.merchant);

    const onCreateMerchant = useCallback((data)=>{
        createMerchant(data);
    },[createMerchant]);
console.log('createMerchantError UI',createMerchantError);
    return <div className={'container'}>
        <div className={'border-bottom'}>
            <h1>Create a New Merchant</h1>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" style={{display: 'none'}}>
            <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </symbol>

            <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                <path
                    d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
            </symbol>
        </svg>

        {
            createMerchantError && <div className="mt-2 alert alert-danger d-flex align-items-center" role="alert">
                <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
                    <use xlinkHref="#exclamation-triangle-fill"/>
                </svg>
                <div>
                    Error creating merchant
                </div>
                <button type="button" className="btn-close" onClick={() => setData({key:'createMerchantError', value:null})}></button>
            </div>
        }
        {
            merchantCreated && <div className="mt-2 alert alert-success d-flex align-items-center" role="alert">
                <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:">
                    <use xlinkHref="#check-circle-fill"/>
                </svg>
                <div>
                    Merchant Created Successfully!!
                </div>
                <button type="button" className="btn-close ms-auto" onClick={() => setData({key:'merchantCreated', value:null})}></button>
            </div>
        }
        <div className={`row justify-content-center ${(merchantCreated || createMerchantError) ? 'mt-2' : 'mt-5'}`}>
            <div className={'col-6'}>
                <FormMerchant
                    onSave={onCreateMerchant}
                />
            </div>
        </div>
        {
            isSaving && <LoadingModal isLoading={true} message={'Creating Merchant'}/>
        }
    </div>;
}

export default AddMerchant;
