import { action } from 'easy-peasy';
import * as Actions from './actions'
const userModel = {
    fetchingPagination:false,
    pagination:{pages: 0, elements: 0,total: 0},
    error:null,
    fetchingUsers:false,
    arrUsers:[],
    userSelected: null,
    updating:false,
    update:action((state, payload) => {
        state.arrUsers = [...state.arrUsers].map(m => m.id === payload.id ? {...payload} : m);
    }),
    remove:action((state, payload) => {
        state.arrUsers = [...state.arrUsers].filter(m => m.id !== payload.userId);
    }),
    setData:action((state,payload) => {
        state[payload.key] = payload.value
    }),
    fetchUsers: Actions.fetchUsers,
    setUserEnable: Actions.setUserEnable,
    fetchUser:Actions.fetchUser
};
export default userModel;
