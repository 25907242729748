import {action, thunk} from "easy-peasy";
import axiosInstance from "../../services/axios";

export const fetchProducts = thunk(async (actions, payload) => {
    actions.setData({key:'isFetching',value:true});
    let url = `/products/auth?withoutMerchantInfo=true&sortByOrderAsc=true&page=${payload.cPage}&elements=${payload.nElem}&computeTotal=true`
    if(payload.filter){
        Object.keys(payload.filter).forEach(key => {
            url += `&${key}=${payload.filter[key]}`;
        })
    }

    axiosInstance.get(url).then(({data})=>{
        if(Array.isArray(data)) {
            actions.setData({key: 'products', value: data})
        }else{
            const {elements, total} = data;
            actions.setData({key: 'products', value: elements});
            actions.setData({key: 'paginator', value: {pages: Math.ceil(total / payload.nElem), elements: payload.nElem,total}});
        }
    }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(()=> {
        actions.setData({key:'isFetching',value:false});
    })
})

export const deleteProduct = thunk(async (actions, payload) => {
    actions.setData({key:'deleting',value:true});
    axiosInstance.delete(`/products/${payload.productId}`).
    then(()=>{
            actions.remove({productId:payload.productId})
    }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(()=> {
        actions.setData({key:'deleting',value:false});
    })
})

export const setPublish = thunk(async (actions, payload) => {
    const {productId, publish} = payload;

    actions.setData({key:'publishingProduct',value:true});

    axiosInstance.put(`/products/${productId}/publish`,{publish})
        .then(({data})=>{
            actions.update(data)
    }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(()=> {
        actions.setData({key:'publishingProduct',value:false});
    })
})


