import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheck, faBan, faCog,faMinus,} from '@fortawesome/free-solid-svg-icons'
const ProductRow = props => {

    const {deleteProduct,setPublish} = props;
    const {id,images,price,category,created,publish,deleted}= props.data;

    return(
        <tr>
            <td className={"text-center"}><img
                onClick={() => {
                props.onPreviewImg && props.onPreviewImg(images[0].URLWebp ?? images[0].URL)
            }}
                     style={{borderRadius:'4px', height:'40px', cursor:'zoom-in'}}
                     src={images[0].thumbURLWebp} alt=""/></td>
            <td className="align-middle text-start ps-5">{price !== null?price:0.00}</td>
            <td className="align-middle ps-2">{category}</td>
            <td className="align-middle text-center">{moment(created).format("YYYY/MM/DD")}</td>
            <td className="align-middle text-center">{deleted?<FontAwesomeIcon color={'#dc3545'} icon={faCheck}/>:<FontAwesomeIcon color={'#28a745'} icon={faMinus}/>}</td>
            <td className="align-middle text-center">{publish?<FontAwesomeIcon color={'#28a745'} icon={faCheck}/>:<FontAwesomeIcon color={'#dc3545'} icon={faBan}/>}</td>
            <td className="align-middle text-center">
                <div className="dropdown">
                    <button
                        className="btn"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <FontAwesomeIcon color={'#dc3545'} icon={faCog}/>
                    </button>
                    <ul className="dropdown-menu">
                        <li> <a className="dropdown-item" onClick={() => setPublish(id,!publish)}>{publish?'Unpublish':'Publish'}</a></li>
                        <div className="dropdown-divider"/>
                        <li>
                            <a className="dropdown-item" onClick={() => deleteProduct(id)}>Delete</a>
                        </li>
                    </ul>
                </div>
            </td>
        </tr>
    )
}

export default ProductRow

