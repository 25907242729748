import React from "react";
import PropTypes from 'prop-types';
const LoadingModal=props=>{
    return (props.isLoading? <div style={{
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        position: 'fixed',
        left: '0px',
        right: '0px',
        top: '0px',
        bottom: '0px',
        zIndex: '5000',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    }}>
        <div className="spinner-border text-primary" role="status">
            <span className="sr-only">{props.message ?? 'Loading...'}</span>
        </div>
    </div>:<div></div>)
}
LoadingModal.prototype={
    isLoading:PropTypes.bool.isRequired
}
export default LoadingModal
