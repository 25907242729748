import React, {useCallback, useEffect, useState} from "react";
import {Paginator, LoadingModal, FilterCountry,ConfirmModal} from "../../commons/index";
import ProductRow from "./views/Product";

import {N_ELEMENT_BY_PAGE} from "../../commons/configs";
import {useStoreActions, useStoreState} from "easy-peasy";
const Products = () =>{
    const [nElem,setNElem]=useState(N_ELEMENT_BY_PAGE)
    const [cPage,setCPage]=useState(1)
    const [code,setCode]=useState('ALL')
    const [arrProducts,setArrProducts]=useState([])
    const [displayConfirm,setDisplayConfirm]=useState(false)
    const [productIdSelected,setProductIdSelected]=useState(null)
    const [filter,setFilter]=useState(null)

    const {isFetching,products,paginator,error} = useStoreState((state) => state.products);
    const {merchantSelected} = useStoreState((state) => state.merchant);
    const {fetchProducts,deleteProduct,setPublish} = useStoreActions((actions) => actions.products);
    const tHead=["Photo","Price","Category","Created","Deleted by user","Published","Actions"]

    useEffect(()=>{
        fetchProducts({nElem, cPage:1});
    },[])

    useEffect(()=>{
        if(products.length > 0)
            formatProducts(products)
    },[products])

    useEffect(()=>{
        if(nElem !== N_ELEMENT_BY_PAGE){
            fetchProducts({nElem, cPage:1, filter})
        }

    },[nElem])

    const handleOnClickBtn=(evt)=>{
        evt.stopPropagation()
        const cp = parseInt(evt.target.value)
        fetchProducts({nElem, cPage:cp, filter})
        setCPage(cp)
    }

    const _deleteProduct=(productId)=>{
        setProductIdSelected(productId)
        setDisplayConfirm(true)
    }

    const _onAcceptDelete=()=>{
        setDisplayConfirm(false)
        deleteProduct(productIdSelected)
    }

    const _setPublish=(productId,publish)=>{
        setPublish({productId, publish})
    }

    const [imgUrl, setImgUrl] = useState('');
    const [isOpenImgPreview, setIsOpenImgPreview] = useState(false);
    const onPreviewImg = useCallback(url => {
        setImgUrl(url);
        setIsOpenImgPreview(true);
    },[])

    const formatProducts=(pp)=>{
        if(pp){
            let arr = pp.map((p,key)=>{

                return <ProductRow key={key}
                                   data={p}
                                   deleteProduct={_deleteProduct}
                                   setPublish={_setPublish}
                                   onPreviewImg={onPreviewImg}
                />
            })
            setArrProducts(arr)
        }
    }
    const onChangeNElement=nElements=>{
        setNElem(nElements)
    }

    const _handleCountry=(code)=>{
        let f={}
        if(merchantSelected){
            f = code !== 'ALL'? {"fromMerchant":merchantSelected.id,"country":code}:{"fromMerchant":merchantSelected.id}
            fetchProducts({nElem, cPage:1,filter: f})
        }else{
            f = code !== 'ALL'?{"country":code}:null
            fetchProducts({nElem, cPage:1,filter: f})
        }
        setCode(code)
        setFilter(f)
    }

    const display=()=>{

        if(isFetching){
            return(
              <>
                  <FilterCountry code={code}  title={"Products"} handleCountry={_handleCountry}/>
                  <LoadingModal isLoading={isFetching} />
                  {
                      arrProducts.length > 0 && <Paginator
                        cPage={cPage}
                        data={arrProducts}
                        head={tHead}
                        nElem={nElem}
                        handleOnClickBtn={handleOnClickBtn}
                        paginator={paginator}
                      />
                  }

              </>
            )

        }

        return (
          <>
              {
                  error && <>
                      <h1>Products</h1>
                      <div className="alert alert-danger" role="alert">
                          {error}
                      </div>
                  </>
              }
              <FilterCountry code={code} title={"Products"} handleCountry={_handleCountry}/>
              <ConfirmModal display={displayConfirm}
                            title={"Confirm!"}
                            text={"Are you sure you want to delete this Product?"}
                            _onCancel={()=>setDisplayConfirm(false)}
                            _onAccept={_onAcceptDelete} />
              {
                  arrProducts.length > 0 && <Paginator
                    cPage={cPage}
                    data={arrProducts}
                    head={tHead}
                    nElem={nElem}
                    handleOnClickBtn={handleOnClickBtn}
                    paginator={paginator}
                    onChangeNElement={onChangeNElement}
                  />
              }
              {
                  isOpenImgPreview && (<div className="modal fade show bg-light" id="exampleModal" style={{display:'block'}}>
                      <div className="modal-dialog modal-lg">
                          <div className="modal-content">
                              <div className="modal-header">
                                  <button onClick={() => setIsOpenImgPreview(false)} type="button" className="btn-close" aria-label="Close"></button>
                              </div>
                              <div className="modal-body">
                                  <img style={{
                                      objectFit:'cover',
                                      width:'100%',
                                      height:'auto'
                                  }} src={imgUrl} alt=""/>
                              </div>

                          </div>
                      </div>
                  </div>)
              }
          </>
        )

    }

    return( display() )
}
export default Products
