import {thunk} from "easy-peasy";
import axiosInstance from "../../services/axios";

export const fetchOrders = thunk(async (actions, payload) => {
   let url = `sales/merchant/orders/auth?sortByCreatedDesc=true&page=${payload.cPage}&elements=${payload.nElem}&computeTotal=true`;
    Object.keys(payload.filter).forEach(key => {
        url += `&${key}=${payload.filter[key]}`;
    })
    actions.setData({key:'error', value:null});
    actions.setData({key:'fetchingOrders', value:true})
    axiosInstance.get(url).then(({data})=>{
        if(Array.isArray(data)) {
            actions.setData({key: 'arrOrders', value: data})
        }else{
            const {orders, total} = data;
            actions.setData({key: 'arrOrders', value: orders});
            actions.setData({key: 'pagination', value: {pages: Math.ceil(total / payload.nElem), elements: payload.nElem,total}});
        }
    }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(()=> {
        actions.setData({key:'fetchingOrders', value:false})
    })
});
