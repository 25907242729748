import {LIVE} from '../../commons/configs'
import {thunk} from "easy-peasy";
import axiosInstance from "../../services/axios";

export const fetchPhotos = thunk(async (actions, payload) => {

    let url = `/photo/auth?page=${payload.cPage}&elements=${payload.nElem}&sortByCreated=true&computeTotal=true`;
    if(payload.filter){
        Object.keys(payload.filter).forEach(key => {
            url += `&${key}=${payload.filter[key]}`;
        })
    }
    actions.setData({key:'fetching',value:true});
    axiosInstance.get(url).then(({data})=>{
        if(Array.isArray(data)) {
            actions.setData({key: 'arrPhotos', value: data})
        }else{
            const {photos, total} = data;
            actions.setData({key: 'arrPhotos', value: photos});
            actions.setData({key: 'paginator', value: {pages: Math.ceil(total / payload.nElem), elements: payload.nElem,total}});
        }
    }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(()=> {
        actions.setData({key:'fetching',value:false});
    });
});

export const deletePhoto = thunk(async (actions, payload) => {
    actions.setData({key:'deletingPhoto', value:true});
    axiosInstance.delete(`/photo/${payload.photoId}`)
        .then(()=> {
            actions.remove({id:payload.photoId})
        }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(() => {
        actions.setData({key:'deletingPhoto', value:false});
    })
});

export const fetchPhotoShoot = thunk(async (actions, payload) => {
    actions.setData({key:'fetchingPhotoshoots', value:true});
      axiosInstance.get('/photo/shoots',{leve:LIVE})
        .then(({data})=>{
            actions.setData({key:'arrPhotoshoots', value:data})
        }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(() => {
        actions.setData({key:'fetchingPhotoshoots', value:false});
    })
});

export const addPhoto = thunk(async (actions, payload) => {

    let formData=new FormData();
    formData.append("files",payload.file)
    formData.append('phoneNumber',payload.phoneNumber)
    formData.append('region',payload.country)
    formData.append('merchantId',payload.merchantId)
    if(payload.photoshoot !== -1)
        formData.append('photoshoot',payload.phothoshoot)
    formData.append('handle',payload.handle)
    formData.append('text',payload.text)
    formData.append('live',LIVE)
    actions.setData({key:'addingPhoto', value:true});
    axiosInstance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    axiosInstance.post('/photo',formData)
        .then(({data})=>{
            actions.setData({key:'added', value:true})
        }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(() => {
        axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
        actions.setData({key:'addingPhoto', value:false});
    })
});

/*

export const addPhoto=(files,phoneNumber,region,merchantId,photoshoot,handle,text)=>{
    return (dispatch,getState)=>{
        dispatch(addingPhoto())
        const {access_token}=getState().app
        let formData=new FormData()
        formData.append("files",files)
        formData.append('phoneNumber',phoneNumber)
        formData.append('region',region)
        formData.append('merchantId',merchantId)
        if(photoshoot !== -1)
        formData.append('photoshoot',photoshoot)
        formData.append('handle',handle)
        formData.append('text',text)
        formData.append('live',LIVE)

        return fetch(API_URL+'/photo/add',{
            body:formData,
            method:'POST',
            headers:{
                'Authorization':`Bearer ${access_token}`
            }
        }).then(res=>{
            if(res.status===200)
                return res.json()
            else
                throw {errorCode:res.status}
        }).then(result=>{
            return dispatch(addedPhoto(result))
        }).catch(err=>{
            if(err.errorCode === undefined)
                return dispatch(onError(999))
            return dispatch(onError(err.errorCode))
        })
    }
}


 */


export const FETCHING_MERCHANTS='FETCHING_MERCHANTS'
export const RECEIVED_MERCHANTS='RECEIVED_MERCHANTS'
export const FETCHING_PHOTOS='FETCHING_PHOTOS'
export const FETCHING_PAGINATOR='FETCHING_PAGINATOR'
export const RECEIVED_PHOTOS='RECEIVED_PHOTOS'
export const RECEIVED_PAGINATOR='RECEIVED_PAGINATOR'
export const DELETING_PHOTO='DELETING_PHOTO'
export const DELETED_PHOTO='DELETED_PHOTO'

export const ON_ERROR='ON_ERROR'

export const FETCHING_PHOTOSHOOT='FETCHING_PHOTOSHOOT'
export const RECEIVED_PHOTOSHOOT='RECEIVED_PHOTOSHOOT'
export const ADDING_PHOTO='ADDING_PHOTO'
export const ADDED_PHOTO='ADDED_PHOTO'
