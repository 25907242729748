import React, {useState, useEffect, useCallback} from "react";
import {useStoreActions, useStoreState} from "easy-peasy";
import {FilterCountry, LoadingModal,InformationModal} from "../../../commons";

const AddPhoto = () =>{
    const {error,addingPhoto,added,arrPhotoshoots,fetchingPhotoshoots} = useStoreState((state) => state.photos);
    const {merchants,fetchingMerchant} = useStoreState((state) => state.merchant);
    const {fetchPhotoShoot,addPhoto} = useStoreActions((actions) => actions.photos);
    const {fetchMerchants} = useStoreActions((actions) => actions.merchant);
    const [file,setFile]=useState(null)
    const [phothoshoot,setPhotoshoot]=useState(-1)
    const [merchant,setMerchant]=useState(null)
    const [phoneNumber,setPhoneNumber]=useState(null)
    const [handle,setHandle]=useState(null)
    const [text,setText]=useState(null)
    const [code,setCode]=useState('ALL')
    const [displayInformation,setDisplayInformation]=useState(false)

    useEffect(()=>{
        fetchPhotoShoot()
        fetchMerchants({cPage:1,nElem:15})
    },[])

    const onChangeFile=evt=>{
        setFile(evt.target.files[0])
    }

    const onChangePhotoshoot=evt=>{
        setPhotoshoot(parseInt(evt.target.value) )
    }

    const onChangeMerchant=evt=>{
        setMerchant(merchants[evt.target.selectedIndex])
    }

    const onChangePhone=evt=>{
        setPhoneNumber(evt.target.value)
    }

    const onChangeHandle=evt=>{
        let temp = evt.target.value
        temp = temp[0] !== '@'?'@'+temp :temp
        setHandle(temp)
    }

    const onChangeText=evt=>{
        setText(evt.target.value)
    }

    const _handleCountry=(code)=>{
        let filter = code !== 'ALL'? {"country":code}:null
        fetchMerchants({filter})
        setCode(code)
    }

    const isValid=()=>{
        if(file === null)
            return false
        if(merchant === null)
            return false
        if(handle === null)
            return false
        if(!(/^\(?(\d{3})\)?-?(\d{3})-?(\d{4})$/.test(phoneNumber)))
            return false
        return true
    }

    const _submit=(evt)=>{
        evt.preventDefault()

        if(isValid()) {
            addPhoto({file, phoneNumber, country: merchant.country, merchantId: merchant.id, phothoshoot, handle, text})

            setFile(null)
            setPhoneNumber(null)
            setHandle(null)
            setText(null)
            setDisplayInformation(true)
            if (phothoshoot === -1)
                fetchPhotoShoot()
        } else {
            console.warn('Not Valid!!!')
        }
    }

    const openFileBrowser = useCallback(evt => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = _ => {
            let files =   Array.from(input.files);
            setFile(files[0]);
        };
        input.click();
    },[])


    const display=()=>{
        if(error)
            return (
                <>
                    <div className="alert alert-danger" role="alert">
                        Error MSG
                    </div>
                </>
            )
        if(fetchingMerchant || addingPhoto || fetchingPhotoshoots){
            return ( <>
                <FilterCountry code={code}  title={"Add Photos"} handleCountry={_handleCountry}/>
                <LoadingModal isLoading={fetchingMerchant || addingPhoto ||fetchingPhotoshoots} />
                <div className="card">
                    <div className="card-header">
                        <div className="card-title text-center">
                            <div className="card-text">
                                Add new Photo
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <form>
                            <div className="form-group">
                                    <input type="file" accept={'image/*'} className="form-control" id="file"
                                           onChange={onChangeFile} required/>
                                    <label className="form-control"
                                           htmlFor="file">{file !== null ? file.name : 'Choose photo'}</label>
                                    <div className="invalid-feedback">Invalid</div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="photoshop">Photoshoot</label>
                                    <select className="form-control" id="photoshoot" required
                                            onChange={onChangePhotoshoot}>

                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="merchant">Merchant</label>
                                    <select className="form-control" id="merchant" onChange={onChangeMerchant} required>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="phoneNumber">User (phone number)</label>
                                    <input type="phone" className="form-control" id="phoneNumber"
                                           placeholder="+123 4567890" onChange={onChangePhone} required/>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="handle">Handle (include starting @)</label>
                                    <input type="text" className="form-control" id="handle"
                                           placeholder="" onChange={onChangeHandle} required/>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="desc">Text</label>
                                    <textarea className="form-control" id="desc" rows="3"
                                              onChange={onChangeText}></textarea>
                                </div>
                                <label>
                                    Copy + paste emoji's from <a href="https://getemoji.com/">getemoji.com</a>
                                </label>
                                <div className="form-group d-flex justify-content-end">
                                    <button type="submit" className="btn btn-primary ">Submit</button>
                                </div>
                        </form>

                    </div>

                </div>
            </>)
        }
        return ( <>
            <FilterCountry code={code}  title={"Add Photos"} handleCountry={_handleCountry}/>
            <InformationModal
                display={displayInformation}
                text={"Photo added successfully!!"}
                _onAccept={()=>setDisplayInformation(false)}
            />
            <div className="card border-0 shadow-lg">
                <div className="card-header">
                    <h5 className="card-title text-center"> Add new Photo</h5>
                </div>
                <form id={"form"} onSubmit={_submit}>
                    <div className="card-body">

                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <div className="input-group form-group mb-3">
                                        <label htmlFor="fileGroup">Select new photo</label>
                                        <div id={'fileGroup'} className="input-group">
                                            <button
                                                className="btn btn-outline-secondary"
                                                type="button"
                                                id="button-addon1"
                                                onClick={openFileBrowser}
                                            >
                                                Choose
                                            </button>
                                            <input type="text"
                                                   id={'file'}
                                                   name={'file'}
                                                   className="form-control" placeholder=""
                                                   readOnly
                                                   aria-label=""
                                                   aria-describedby="button-addon1"
                                                   value={file !== null ? file.name : 'Choose photo'}
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group mb-3">
                                        <label htmlFor="photoshoot">Photoshoot</label>
                                        <select className="form-control" id="photoshoot" required
                                                onChange={onChangePhotoshoot}>
                                            <option value={-1}>New Photoshoot</option>
                                            {
                                                arrPhotoshoots.map(v => (
                                                    <option key={v} value={v}>{'Photoshoot ' + v}</option>))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group mb-3">
                                        <label htmlFor="merchant">Merchant</label>
                                        <select className="form-control" id="merchant" onChange={onChangeMerchant}
                                                required>
                                            <option value="-1">Select Merchant</option>
                                            {
                                                merchants.map((m, k) => (
                                                    <option key={k} value={m.id}>{m.companyName}</option>))
                                            }

                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group mb-3">
                                        <label htmlFor="phoneNumber">User (phone number)</label>
                                        <input type="tel" className="form-control" id="phoneNumber"
                                               placeholder="123 4567890" onChange={onChangePhone} required/>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group mb-3">
                                        <label htmlFor="desc">Text</label>
                                        <textarea className="form-control" id="desc" rows="3"
                                                  onChange={onChangeText}></textarea>
                                    </div>
                                    <label>
                                        Copy + paste emoji's from <a href="https://getemoji.com/">getemoji.com</a>
                                    </label>

                                </div>
                                <div className="col-6">
                                    <div className="form-group mb-3">
                                        <label htmlFor="handle">Handle (include starting @)</label>
                                        <input type="text" className="form-control" id="handle"
                                               placeholder="" onChange={onChangeHandle} required/>
                                    </div>
                                </div>

                            </div>

                        </div>

            </div>
                    <div className="card-footer bg-white mt-4 form-group d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary ">Submit</button>
                    </div>
                </form>
            </div>

        </>)

    }
    return display()
}
export default AddPhoto
