import {action} from 'easy-peasy';
import * as Actions from './actions'

const merchantModel = {
    updatedAt:Date.now(),
    isFetching:true,
    publishing:false,
    error:false,
    merchants:[],
    merchantSelected:null,
    totalUnapproved:0,
    token:'',
    isSaving:false,
    merchantCreated:null,
    createMerchantError:null,
    paginator:{pages: 1, elements: 15,total: 15},
    updateMerchant:action((state, payload) => {
        state.merchants = [...state.merchants].map(m => m.id === payload.id ? {...payload} : m);
    }),
    removeMerchant:action((state, payload) => {
        state.merchants = [...state.merchants].filter(m => m.id !== payload.id);
    }),
    setData:action((state,payload) => {
        state[payload.key] = payload.value;
    }),
    fetchMerchants:Actions.fetchMerchants,
    fetchMerchantsFilter:Actions.fetchMerchantsFilter,
    orderByField:Actions.orderByField,
    onSet: Actions.onSet,
    getUserToken: Actions.getUserToken,
    onDeleteMerchant:Actions.onDeleteMerchant,
    getMerchantById:Actions.getMerchantById,
    selectMerchant:'',
    createMerchant:Actions.createMerchant,
}

export default merchantModel
