import React from "react";
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowRightFromBracket, faCircleInfo, faEye, faInfo} from '@fortawesome/free-solid-svg-icons';


const RowOrder = (props) =>{
  const { onSelectedOrderDetails, order } = props
  const { id,merchant, shippingAddress, products, created, } = order
  const { companyName, phoneNumber, address, postalCode } = merchant
  const { fullName } = shippingAddress

  const contact = phoneNumber ? phoneNumber : (address + ' ' + postalCode)
  const date = moment(created).format('lll');

  const getPriceString = () => {
    let priceString = ''
    if(products && products.length > 0){
      const currencies = []
      products.forEach(product => {
        if(!currencies.includes(product.product.currency)){
          currencies.push(product.product.currency)
        }
      })
      currencies.forEach(currency => {
        let amount = 0;
        products.forEach(product => {
          if( currency = product.product.currency){
            amount+=product.amount
          }
        })
        priceString += (currency +' '+ (amount/100)+' ')
      })
    }
    return priceString
  }

  const _onDetail=(evt)=>{
    evt.preventDefault()
    onSelectedOrderDetails({...order, priceString: getPriceString(), dateString:date})
  }

  return <tr className="align-middle">
    <td className="align-middle ps-2">{companyName}</td>
    <td className="align-middle ps-2">{fullName}</td>
    <td className="align-middle ps-5">{getPriceString()}</td>
    <td className="align-middle ps-2">{date}</td>
    <td className="align-middle ps-4">{contact}</td>
    <td className="align-middle text-center">
      <button className="btn" onClick={_onDetail} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Details">
        <FontAwesomeIcon icon={faEye}/>
      </button>
    </td>
  </tr>
}

export default RowOrder
