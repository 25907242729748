import React, {useState, useEffect, useRef, useMemo, useCallback} from "react";
import {useStoreActions, useStoreState} from "easy-peasy";
import {N_ELEMENT_BY_PAGE} from "../../commons/configs";
import { faSearch, faFilter} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Merchant from "./views/Merchant";
import { LoadingModal, ConfirmModal, FilterCountry, Paginator} from "../../commons";

const filterOptions = [{value:0, label:'All'},
    {value:1, label:'Recommended'},
    {value:2, label:'Galleries'},
    {value:3, label:'Design'},
    {value:4, label:'Interior'},
    {value:5, label:'P.Web'},
    {value:6, label:'U.Web'},
    {value:7, label:'P.Map'},
    {value:8, label:'U.Map'},
]

const Merchants = () => {
    const {merchants,error, isFetching, paginator,
        token, publishing} = useStoreState((state) => state.merchant);
    const {
        fetchMerchantsFilter,
        onSet,
        getUserToken,
        onDeleteMerchant,
        setData
    } = useStoreActions((actions) => actions.merchant);

    const [searchTerm, setSearchTerm] = useState('');
    const [nElem,setNElem]=useState(N_ELEMENT_BY_PAGE);
    const [cPage,setCPage]=useState(1)
    const [filter,setFilter]=useState({})
    const [category,setCategory]=useState(0)
    const [code,setCode]=useState('ALL')
    const [displayConfirm,setDisplayConfirm]=useState(false)
    const [merchantIdSelected,setMerchantIdSelected]=useState(null)
    const [arrMerchants,setArrMerchants]=useState([])
    const [order,setOrder]=useState({
        sortByName:null,
        sortBySlug:null,
        sortByEmail:null,
        sortByCompanyName:null,
        sortByPriority:null,
        sortByCreated:'desc'
    });
    const lastNElement = useRef(N_ELEMENT_BY_PAGE);

    useEffect(() => {
        fetchMerchantsFilter({nElem, cPage, filter,order});
    },[order])

    useEffect(() => {
        if(token){
           let ele  = document.createElement('a')
           ele.href = "https://merchant.goyuge.com/token?access_token="+token.access_token
           ele.target = "_blank"
           ele.click();
           setData({key:'token', value:null})
        }
    }, [token]);

    const onChangeOrder = useCallback((field) => {
        setOrder(current => {
            const temp = {...current};
            Object.keys(temp).forEach(key => {
                if(key !== field){
                    temp[key] = null;
                }else {
                    temp[key] = temp[key] === null ? "desc" : temp[key] === 'desc' ? "asc" : "desc";
                }
            })
            return temp;
        });
    },[])

    const tHead = useMemo(() => {
        return [
            {text: "Company",sortable:true,order:order.sortByCompanyName,callback:() => onChangeOrder('sortByCompanyName')},
            {text: "Slug",sortable:true,order:order.sortBySlug,callback:() => onChangeOrder('sortBySlug')},
            "Address","Postcode",
            {text: "Email",sortable:true,order:order.sortByEmail,callback:() => onChangeOrder('sortByEmail')},
            {text: "Name",sortable:true,order:order.sortByName,callback:() => onChangeOrder('sortByName')},
            "Prod Count","Open Hour","Cover photo","P.Web","P.Map","P. App","P.Payment","Suggested","Sales",
            {text:"Created",sortable: true,order:order.sortByCreated, callback:() => onChangeOrder('sortByCreated') },
            "Actions"]
    },[order,onChangeOrder])

    useEffect(()=>{
        if(nElem !== lastNElement.current){
            fetchMerchantsFilter({nElem, cPage:1, filter,order});
            lastNElement.current = nElem;
        }
    },[nElem])

    const setPaymentStatus = useCallback((merchantId,enabledToSales)=>{
        onSet({merchantId,field:'enabledToSales', value:enabledToSales, urlKey:'publishPayment'})
    },[onSet])

    const setPublishWeb = useCallback((merchantId,publish)=>{
        onSet({merchantId, value:publish, urlKey:'publishWeb'})
    },[onSet])

    const setApproved = useCallback((merchantId,approved, nElem, nPage)=>{
        onSet({merchantId, value:approved, urlKey:'approved', field:'approved'})
    },[onSet])

    const setPublishMap = useCallback((merchantId,publish)=>{
        onSet({merchantId, value:publish, urlKey:'publishMap'})
    },[onSet])

    const setPriority = useCallback((merchantId,priority)=>{
        onSet({merchantId,field:'priority', value:priority, urlKey:'prioritize'});
    },[onSet])

    const setPublish = useCallback((merchantId,publish)=>{
        onSet({merchantId, value:publish, urlKey:'publish'})
    },[onSet])

    const deleteMerchant = useCallback((merchantId)=>{
        setDisplayConfirm(true)
        setMerchantIdSelected(merchantId)
    },[])

    const selectMerchant = useCallback((merchant)=>{
        setData({key:'merchantSelected',value:merchant})
    },[setData])

    const _onAcceptDelete = useCallback(() => {
        setDisplayConfirm(false)
        onDeleteMerchant({merchantId:merchantIdSelected})
    },[merchantIdSelected,onDeleteMerchant])

    const _loginOnMerchant = useCallback(userId => {
        getUserToken({userId})
    },[getUserToken])

    const handleFilter = useCallback((evt)=>{
        evt.stopPropagation()
        const index = parseInt(evt.target.value)
        let f = {...filter,"computeProductSummary":true}
        delete f.recommended
        delete f.category
        delete f.publishOnWeb
        delete f.publishOnMap

        if(index === 1){
            f = {...f,"recommended":true}
        }
        if(index === 2){
            f={...f,"category":"ART_DESIGN"}
        }
        if(index === 3){
            f={...f,"category":"DESIGN"}
        }
        if(index === 4){
            f={...f,"category":"HOME_FURNITURE"}

        }
        if(index === 5){
            f={...f,"publishOnWeb":true}
        }
        if(index === 6){
            f={...f,"publishOnWeb":false}
        }
        if(index === 7){
            f={...f,"publishOnMap":true}
        }
        if(index === 8){
            f={...f,"publishOnMap":false}
        }
        fetchMerchantsFilter({nElem, cPage, filter:f,order})
        setFilter(f)
        setCategory(index)
    },[filter,order,nElem, cPage,fetchMerchantsFilter])

    const handleOnClickBtn=(evt)=>{
        evt.stopPropagation()
        const cp =parseInt(evt.target.value)
        fetchMerchantsFilter({nElem, cPage: cp, filter,order})
        setCPage(cp)
    }

    const _handleCountry = useCallback((code)=>{
        let f = {...filter,"computeProductSummary":true}
        if(code === 'ALL'){
            delete f.country;
        }else {
            f={...f,country:code};
        }
        fetchMerchantsFilter({nElem, cPage, filter:f,order})
        setCode(code)
        setFilter(f)
    },[nElem, cPage, filter,order,fetchMerchantsFilter])

    const formatMerchants= useCallback(()=>{
        if(merchants !== undefined){
            let m = merchants.map((m)=>(<Merchant
                    key={m.id}
                    data={m}
                    setPriority={setPriority}
                    setPublishMap={setPublishMap}
                    setPublishWeb={setPublishWeb}
                    setApproved={setApproved}
                    deleteMerchant={deleteMerchant}
                    selectMerchant={selectMerchant}
                    setPublish={setPublish}
                    loginOnMerchant={_loginOnMerchant}
                    setPaymentStatus={setPaymentStatus}
                    nElem={nElem}
                    nPage={cPage}
                />
            ))
            setArrMerchants(m)
        }
    },[merchants, setPriority, setPublishMap, setPublishWeb, setApproved, selectMerchant, setPublish, _loginOnMerchant, setPaymentStatus, nElem, cPage, deleteMerchant])

    useEffect(()=>{
        formatMerchants()
    },[ merchants])

    const onChangeNElement=nElements=>{
        setNElem(nElements)
    }

    const onChangeInputSearch = useCallback(evt => {
        setSearchTerm(evt.target.value)
    },[])

    const onSubmitSearchMerchant = useCallback(evt => {
        evt.preventDefault();
        evt.stopPropagation();
        fetchMerchantsFilter({nElem,cPage: 1, filter:!searchTerm ? null : {queryCompany: searchTerm},order})
        setFilter(current => {
            let f = {...current};
            if(!searchTerm){
                delete f.queryCompany;
            }else{
                f = {...f, queryCompany: searchTerm}
            }
            return f;
        })

    },[searchTerm,order,fetchMerchantsFilter, nElem])

    const subHeader=()=>{
        return (<div className="row w-100">
            <div className="col-4">
                <form  className="form-inline" noValidate>
                    <div className="input-group input-group-sm w-100">
                        <input value={searchTerm} onChange={onChangeInputSearch} type="text" className="form-control" placeholder="All Merchants"/>
                            <div className="input-group-append ">
                                <button
                                    className="btn btn-secondary btn-sm"
                                    type="submit"
                                    onClick={onSubmitSearchMerchant}
                                    style={{borderTopRightRadius: '.25rem', borderBottomRightRadius: '.25rem'}}>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </button>
                            </div>
                        <div className="invalid-feedback">
                            Please provide a Merchant´s name.
                        </div>
                    </div>
                </form>
            </div>

            <div className="col-8 d-flex justify-content-end pe-0">
                    <div>
                        <select
                            className="form-select"
                            aria-label="filter"
                            onChange={handleFilter}
                            value={category}
                        >
                            {
                                filterOptions.map((item, index) => (<option value={item.value}>{item.label}</option>))
                            }
                        </select>
                    </div>
                <div className="d-flex align-items-center ms-1">
                       <span>
                           <FontAwesomeIcon icon={faFilter}/>
                       </span>
                </div>
            </div>

        </div>)
    }

    const display=()=>{
        if(error){
            return (<>
                <h1>Merchant</h1>
                <div className="alert alert-danger" role="alert">
                    Error MSG
                </div>
            </>)
        }
        if(isFetching || publishing)
            return (
                <>
                    <LoadingModal isLoading={isFetching || publishing} />
                    <FilterCountry code={code} title={"Merchants"} handleCountry={_handleCountry}/>
                    {
                        arrMerchants.length > 0 && <Paginator
                            cPage={cPage}
                            data={arrMerchants}
                            head={tHead}
                            nElem={nElem}
                            handleOnClickBtn={handleOnClickBtn}
                            onChangeNElement={onChangeNElement}
                            paginator={paginator}
                        >{
                            subHeader()
                        }</Paginator>
                    }

                </>)
        return (
            <>
                <ConfirmModal display={displayConfirm}
                              title={"Confirm!"}
                              text={"Are you sure you want to delete this Merchant?"}
                              _onCancel={()=>setDisplayConfirm(false)}
                              _onAccept={_onAcceptDelete}
                />
                <FilterCountry code={code} title={"Merchants"} handleCountry={_handleCountry}/>
                {
                    <Paginator
                        cPage={cPage}
                        data={arrMerchants}
                        head={tHead}
                        nElem={nElem}
                        handleOnClickBtn={handleOnClickBtn}
                        onChangeNElement={onChangeNElement}
                        paginator={paginator}
                    >{
                        subHeader()
                    }
                    </Paginator>
                }
            </>
        )
    }
console.log('paginator:',paginator)
    return( display())
}
export default Merchants;
