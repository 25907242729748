import React from "react";
import PropTypes from 'prop-types';

const ConfirmModal = props=>{
    return (props.display &&
        <div className="modal fade show" id="confirmModal" tabIndex="-1" role="dialog" style={{display: 'block'}}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Confirm!</h5>
                    </div>
                    <div className="modal-body">
                        <p>{props.text}</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={props._onCancel}>No</button>
                        <button type="button" className="btn btn-primary" onClick={props._onAccept}>Yes</button>
                    </div>
                </div>
            </div>
        </div>)
}
ConfirmModal.prototype={
    display:PropTypes.bool.isRequired,
    title:PropTypes.string.isRequired,
    text:PropTypes.string.isRequired,
    _onCancel:PropTypes.func.isRequired,
    _onAccept:PropTypes.func.isRequired
}

export default ConfirmModal
