import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {PhotoRow} from "./views/PhotoRow";
import {FilterCountry, LoadingModal, Paginator,ConfirmModal} from "../../commons";
import {N_ELEMENT_BY_PAGE} from "../../commons/configs";
import {useStoreActions, useStoreState} from "easy-peasy";

const Photos = () => {

    const {arrPhotos,error,fetching,paginator,deletingPhoto} = useStoreState((state) => state.photos);
    const {fetchPhotos, deletePhoto} = useStoreActions((actions) => actions.photos);
    const {setData} = useStoreActions((actions) => actions.merchant);

    const navigate= useNavigate();
    const tHead = ['Photo','Merchant','Region','User','Handle','Text','Photoshoot','Created','Actions']
    const [cPage,setCPage]=useState(1)
    const [code,setCode]=useState('ALL')
    const [filter,setFilter]=useState(null)
    const [dataPhotos,setDataPhotos]=useState([])
    const [displayConfirm,setDisplayConfirm]=useState(false)
    const [photoIdSelected,setPhotoIdSelected]=useState(null)
    const [nElem,setNElem]=useState(N_ELEMENT_BY_PAGE)

    useEffect(()=>{
        fetchPhotos({nElem, cPage:1})
    },[])

    useEffect(()=>{
        if(nElem !== N_ELEMENT_BY_PAGE){
        fetchPhotos({nElem, cPage:1, filter})}
    },[nElem])

    const _deletePhoto = useCallback((photoId)=>{
        setDisplayConfirm(true)
        setPhotoIdSelected(photoId)
    },[])
    const _onAcceptDelete = useCallback(()=>{
        setDisplayConfirm(false)
        deletePhoto({photoId:photoIdSelected})
    },[photoIdSelected])

    const _selectMerchant = useCallback(merchant=>{
        setData({key:'merchantSelected',value:merchant});
        navigate(`/merchants/${merchant.id}`)
    },[])


    const formatData = useCallback(()=>{
            let ph = arrPhotos.map((item,key)=><PhotoRow key={key}
                                                         selectMerchant={_selectMerchant}
                                                         data={item}
                                                         deletePhoto={_deletePhoto}
            />)
            setDataPhotos(ph)
        },[_selectMerchant,_deletePhoto,arrPhotos])

    useEffect(()=>{
        formatData()
    },[arrPhotos])


    const handleOnClickBtn = useCallback((evt)=>{
        evt.stopPropagation()
        const p = parseInt(evt.target.value)
        fetchPhotos({nElem, cPage:p, filter})
        setCPage(p)
    },[])

    const _handleCountry = useCallback((code)=>{
        let f= code !== 'ALL' ? {region:code,"sortByCreated": true}:null
        fetchPhotos({nElem, cPage:1, filter:f})
        setCode(code)
        setFilter(f)
    },[nElem])

    const onChangeNElement = useCallback(nElements=>{
        setNElem(nElements)
    },[])

    const display=()=>{
        if(fetching || deletingPhoto){
            return (<>
                <FilterCountry code={code} title={"Photos"} handleCountry={_handleCountry}/>
                <LoadingModal isLoading={fetching || deletingPhoto} />
                {
                    dataPhotos.length > 0 && <Paginator
                        head={tHead}
                        nElem={nElem}
                        paginator={paginator}
                        handleOnClickBtn={handleOnClickBtn}
                        data={dataPhotos}
                        cPage={cPage}
                        onChangeNElement={onChangeNElement}
                    />
                }
            </>)
        }
        return (<>
            {
                error &&  <>
                    <h1>Photos</h1>
                    <div className="alert alert-danger" role="alert">
                        Error MSG
                    </div>
                </>
            }
            <FilterCountry code={code} title={"Photos"} handleCountry={_handleCountry}/>
           <ConfirmModal display={displayConfirm}
                         title={"Confirm!"}
                         text={"Are you sure you want to delete this Photo?"}
                         _onCancel={()=>setDisplayConfirm(false)}
                         _onAccept={_onAcceptDelete}
           />
            {
                dataPhotos.length > 0 ? <Paginator
                    head={tHead}
                    nElem={nElem}
                    paginator={paginator}
                    handleOnClickBtn={handleOnClickBtn}
                    data={dataPhotos}
                    cPage={cPage}
                    onChangeNElement={onChangeNElement}
                />:<div className="alert alert-danger" role="alert">
                    No Photos to show!!
                </div>
            }

            </>)
    }
    return display()
}
export default Photos
