import { createStore } from 'easy-peasy';
import appModel from '../components/Login/redux/model'
import merchantModel from "../components/Merchant/model";
import merchantUnapprovedModel from "../components/Merchant/modelUnapproved";
import productModel from "../components/Products/model";
import usersModal from "../components/Users/model";
import categoriesModal from "../components/Categories/model";
import countriesModal from "../components/Countries/model";
import photosModal from "../components/Photos/model";
import ordersModal from "../components/Orders/model";
import subscriptionsModel from "../components/Subscriptions/redux/model";

const store = createStore({
 app: appModel,
 merchant:merchantModel,
 products:productModel,
 unapprovedMerchants:merchantUnapprovedModel,
 users:usersModal,
 categories:categoriesModal,
 countries:countriesModal,
 photos:photosModal,
 orders:ordersModal,
 subscriptions:subscriptionsModel
});
export default store;
