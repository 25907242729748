import {action} from "easy-peasy";
import * as Actions from "./actions";
import {deletePhoto} from "./actions";

const photosModel = {
    arrPhotos:[],
    error:null,
    fetching:false,
    fetchingPaginator:false,
    paginator:{pages: 0, elements: 0,total: 0},
    deletingPhoto:false,
    addingPhoto:false,
    added:null,
    arrPhotoshoots:[],
    fetchingPhotoshoots:false,
    setData:action((state,payload) => {
        state[payload.key] = payload.value
    }),
    remove:action((state, payload) => {
        state.arrPhotos = [...state.arrPhotos].filter(ele => ele.id !== payload.id);
    }),
    fetchPhotos:Actions.fetchPhotos,
    deletePhoto:Actions.deletePhoto,
    fetchPhotoShoot:Actions.fetchPhotoShoot,
    addPhoto:Actions.addPhoto
}
export default photosModel;
