import {action} from 'easy-peasy';
import * as Actions from './actions'

const subscriptionsModel = {
    isLoading:true,
    error:false,
    subscriptions:[],
    setData:action((state,payload) => {
        state[payload.key] = payload.value;
    }),
    fetchSubscriptions:Actions.fetchSubscriptions,
};

export default subscriptionsModel;
