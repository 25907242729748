import React, {useState, useEffect, useCallback} from "react";
import {Paginator, FilterCountry, ConfirmModal, LoadingModal} from "../../../commons";
import {N_ELEMENT_BY_PAGE} from "../../../commons/configs";
import {useParams} from "react-router-dom";
import ProductRow from "./Product";
import {useStoreActions, useStoreState} from "easy-peasy";

const ProductsByMerchant = () => {
    const {error,paginator,products,isFetching} = useStoreState((state) => state.products);
    const {merchantSelected,token} = useStoreState((state) => state.merchant);
    const {
        fetchProducts,
        fetchProductsPaginator,
        deleteProduct,
        setPublish,
    } = useStoreActions((actions) => actions.products);
    const {getMerchantById,getUserToken,setData} = useStoreActions((actions) => actions.merchant);
    const params = useParams()
    const [code,setCode]=useState('ALL')
    const [cPage,setCPage]=useState(1)
    const [arrProducts,setArrProducts]=useState([])
    const [displayConfirm,setDisplayConfirm]=useState(false)
    const [productIdSelected,setProductIdSelected]=useState(null)
    const [filter,setFilter]=useState(null)
    const [nElem,setNElem]=useState(N_ELEMENT_BY_PAGE)
    const tHead=["Photo","Price","Category","Created","Deleted by user","Published","Actions"]
    const [imgUrl, setImgUrl] = useState('');
    const [isOpenImgPreview, setIsOpenImgPreview] = useState(false);

    useEffect(()=>{
        if(!!merchantSelected){
            setFilter({"fromMerchant":merchantSelected.id})
        }else{
            if(Object.keys(params).length > 0){
                getMerchantById({merchantId:params.id})
                setFilter({"fromMerchant":params.id})
            }
        }
    },[])

    useEffect(()=>{
        if(products !== undefined)
            formatProducts()
    },[products])

    const onPreviewImg = useCallback(url => {
        setImgUrl(url);
        setIsOpenImgPreview(true);
    },[])

    const formatProducts=()=>{

        if(products){
            let arr = products.map((p,key)=>{
                return <ProductRow key={key}
                                   data={p}
                                   deleteProduct={_deleteProduct}
                                   setPublish={_setPublish}
                                   onPreviewImg={onPreviewImg}
                />
            })
            setArrProducts(arr)
        }
    }

    useEffect(()=>{
        if(nElem !== N_ELEMENT_BY_PAGE){
            fetchProductsPaginator({nElem, filter})
            fetchProducts({nElem,cPage:1, filter})
        }
    },[nElem])

    useEffect(() => {
        if(token){
            let ele  = document.createElement('a')
            ele.href = "https://merchant.goyuge.com/token?access_token="+token.access_token
            ele.target = "_blank"
            ele.click();
            setData({key:'token', value:null})
        }
    }, [token]);

    const _deleteProduct = useCallback((productId)=>{
        setProductIdSelected(productId)
        setDisplayConfirm(true)
    },[])

    const _onAcceptDelete = useCallback(()=>{
        setDisplayConfirm(false)
        deleteProduct({productId:productIdSelected})
    },[productIdSelected])

    const _setPublish = useCallback((productId,publish)=>{
        setPublish({productId, isPublish:publish})
    },[])

    const handleCountry = useCallback((code)=>{
        let f = code !== 'ALL'? {"fromMerchant":merchantSelected.id,"country":code}:{"fromMerchant":merchantSelected.id}
        fetchProductsPaginator({nElem:N_ELEMENT_BY_PAGE, filter:f});
        fetchProducts({nElem:N_ELEMENT_BY_PAGE,cPage:1,filter: f});
        setCode(code);
        setFilter(f);
    },[])

    const handleOnClickBtn = useCallback(evt=>{
        evt.stopPropagation()
        const cp = parseInt(evt.target.value)
        fetchProducts({nElem:N_ELEMENT_BY_PAGE,cPage:cp, filter});
        setCPage(cp)
    },[filter])

    const _selectMerchant = useCallback(evt=>{
        evt.preventDefault()
        getUserToken({userId:merchantSelected?.userId})
    },[merchantSelected?.userId])

    return <div className={'container pt-4'}>
        <FilterCountry code={code} handleCountry={handleCountry} title={"Products By Merchant"}/>
        {
            isFetching &&  <LoadingModal isLoading={true} />
        }
        <h3>Merchant Info</h3>
        <div className={'col-8'}>
            <div className={'card border-0 shadow-lg'}>
                <div className={'card-body'}>
                    <div className={'row'}>
                        <div className={'col-3'}>
                            <p className={"fw-bold me-2"}>Name:</p>
                            <p className={"fw-bold me-2"}>Slug:</p>
                            <p className={"fw-bold me-2"}>Phone Number:</p>
                            <p className={"fw-bold me-2"}>City:</p>
                            <p className={"fw-bold me-2"}>Webshop:</p>
                        </div>
                        <div className={'col-3'}>
                            <p className={"fw-normal"}>{merchantSelected?.companyName}</p>
                            <p className={"fw-normal"}>{merchantSelected?.slug}</p>
                            <p className={"fw-normal"}>{merchantSelected?.phoneNumber}</p>
                            <p className={"fw-normal"}>{merchantSelected?.city}</p>
                            <p className={"fw-normal"}>{merchantSelected?.webshop ?? '-'}</p>
                        </div>
                        <div className={'col-2'}>
                            <p className={"fw-bold me-2"}>Email:</p>
                            <p className={"fw-bold me-2"}>Postcode:</p>
                            <p className={"fw-bold me-2"}>Address:</p>
                            <p className={"fw-bold me-2"}>Location:</p>
                            <p className={"fw-bold me-2"}>Login:</p>
                        </div>
                        <div className={'col-4'}>
                            <p className={"fw-normal"}>{merchantSelected?.email}</p>
                            <p className={"fw-normal"}>{merchantSelected?.postalCode}</p>
                            <p className={"fw-normal"}>{merchantSelected?.address}</p>
                            <p className={"fw-normal"}>{merchantSelected?.location}</p>
                            <p className={"fw-normal"} role={'button'} onClick={_selectMerchant}>Go</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        {
            arrProducts.length > 0 && <Paginator
                title={"Products"}
                cPage={cPage}
                data={arrProducts}
                head={tHead}
                nElem={N_ELEMENT_BY_PAGE}
                handleOnClickBtn={handleOnClickBtn}
                paginator={paginator}
                onChangeNElement={setNElem}
            />
        }
        {displayConfirm && <ConfirmModal display={true}
                                         title={"Confirm!"}
                                         text={"Are you sure you want to delete this Product?"}
                                         _onCancel={() => setDisplayConfirm(false)}
                                         _onAccept={_onAcceptDelete}/>
        }
        {
            isOpenImgPreview && (<div className="modal fade show bg-light" id="exampleModal" style={{display:'block'}}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button onClick={() => setIsOpenImgPreview(false)} type="button" className="btn-close" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <img style={{
                                objectFit:'cover',
                                width:'100%',
                                height:'auto'
                            }} src={imgUrl} alt=""/>
                        </div>

                    </div>
                </div>
            </div>)
        }
    </div>
}
export default ProductsByMerchant
