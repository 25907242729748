import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheck,faBan } from '@fortawesome/free-solid-svg-icons'
const CategoryRow=props=>{
    const {id,region,key,title,publish,productCount=0}=props.data
    const _setPublish=(evt)=>{
        evt.stopPropagation()
        props.setPublish(id,!publish)
    }

    return(<tr>
        <td className="align-middle ps-2"><div className="d-flex align-items-center justify-content-center">
             <span className={"d-block"} style={{width:'50px'}}>
                {region}
             </span>
        </div></td>
        <td className="align-middle ps-2"><div className="d-flex align-items-center justify-content-center">
             <span className={"d-block"} style={{width:'130px'}}>
                {key}
             </span>
        </div></td>
        <td className="align-middle ps-2">
            <div className="d-flex align-items-center justify-content-center">
             <span className={"d-block"} style={{width:'100px'}}>
                {title.en}
             </span>
            </div></td>
        <td className="align-middle text-center">{publish ===true?<FontAwesomeIcon color={'green'} icon={faCheck}/>: <FontAwesomeIcon color={'red'} icon={faBan}/>}</td>
        <td >
        <div className="d-flex align-items-center justify-content-center">
             <span className={"d-block"} style={{width:'50px'}}>
                {productCount}
             </span>
        </div>
        </td>
        <td className="align-middle text-center px-2">{publish ===false ?<button type="button" className="btn btn-sm w-75 btn-outline-success m-1" onClick={_setPublish}>
                <FontAwesomeIcon color={'#28a745'} icon={faCheck}/>
                <span>{' Publish'}</span>
            </button>:
            <button type="button" className="btn btn-sm w-75 btn-outline-danger m-1" onClick={_setPublish}>
                <FontAwesomeIcon color={'#dc3545'} icon={faBan}></FontAwesomeIcon>
                <span>{' Unpublish'}</span>
            </button>}</td>
    </tr>)
}
export default CategoryRow
