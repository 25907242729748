import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faWindowClose, faMapLocationDot, faLocationDot, faLeftLong} from '@fortawesome/free-solid-svg-icons';
import {FilterCountry, LoadingModal, Paginator} from "../../commons";
import {N_ELEMENT_BY_PAGE} from '../../commons/configs'
import RowOrder from "./views/rowOrder";
import {useStoreActions, useStoreState} from "easy-peasy";
const Orders = () =>{

  const {fetchingPagination,pagination,error,fetchingOrders,arrOrders,updating} = useStoreState((state) => state.orders);
  const {
    fetchOrders
   } = useStoreActions((actions) => actions.orders);
  const [elementsAmount,setElementsAmount] = useState(N_ELEMENT_BY_PAGE)
  const [currentPage,setCurrentPage] = useState(1)
  const [countryCode,setCountryCode] = useState('ALL')
  const [dataOrders,setDataOrders] = useState([])
  const [filter,setFilter] = useState({statusNew: true, statusShipping:false, statusDelivered:false})
  const [orderState,setOrderState] = useState('new')
  const [selectedOrder,setSelectedOrder] = useState(null)
  const tHead = ["Store", "User",	"Total price",	"Purchase date",	"Store contact",	"Details"]

  useEffect(()=>{
    formatOrders(arrOrders)
  },[arrOrders])

  const formatOrders=(orders)=>{

    if(orders !== undefined){
      let temp = orders.map((order)=>(<RowOrder onSelectedOrderDetails = {(order) => setSelectedOrder(order)} key={order.id} order={order}/>))
      setDataOrders(temp)
    }
  }

  const handleCurrentPageChange=(evt)=>{
    evt.stopPropagation()
    const page = parseInt(evt.target.value)
    setCurrentPage(page)
  }

  const _handleCountry=(code)=>{
    setCountryCode(code)
    if(code === 'ALL'){
      const newFilter = {...filter}
      delete newFilter.country
      setFilter(newFilter)
    } else {
      setFilter({...filter, country: code})
    }
  }

  const onChangeNElement=nElements=>{
    setElementsAmount(nElements)
  }

  const onChangeOrderState = (event) => {
    setOrderState(event.target.value)
  }

  useEffect(() => {
    switch (orderState) {
      case 'new':
        setFilter({...filter, statusNew: true, statusShipping:false, statusDelivered:false})
        break;
      case 'shipping':
        setFilter({...filter, statusNew: false, statusShipping:true, statusDelivered:false})
        break;
      case 'delivered':
        setFilter({...filter, statusNew: false, statusShipping:false, statusDelivered:true})
        break;
    }
  }, [orderState]);

  useEffect(() => {
    fetchOrders({nElem:elementsAmount, cPage:currentPage, filter})
  },[filter, currentPage, elementsAmount])

  const display=()=>{

    if(fetchingPagination || fetchingOrders || updating){
      return(
        <LoadingModal isLoading={fetchingPagination || fetchingOrders || updating} />
      )
    }

    if(dataOrders !== undefined){
      return(
        <div>
          { !selectedOrder &&
          <>
            <FilterCountry code={countryCode} title={"Orders"} handleCountry={_handleCountry}/>
            <div className="btn-toolbar" style={{float:'right'}}>
              <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                <button type="button" value={'new'} className={"btn mr-0 " + (orderState === 'new'?"btn-secondary":"btn-outline-secondary")} onClick={onChangeOrderState}>{"NEW"}</button>
                <button type="button" value={'shipping'} className={"btn mr-0 " + (orderState === 'shipping'?"btn-secondary":"btn-outline-secondary")} onClick={onChangeOrderState}>{"SHIPPING"}</button>
                <button type="button" value={'delivered'} className={"btn mr-0 " + (orderState === 'delivered'?"btn-secondary":"btn-outline-secondary")} onClick={onChangeOrderState}>{"DELIVERED"}</button>
              </div>
            </div>
            <br/>
            <br/>
            {
                error && <>
                  <div className="alert alert-danger" role="alert">
                    An error has occurred
                  </div>
                </>
            }
            {dataOrders.length === 0 &&
            <div className="alert alert-danger" role="alert">
              No Orders to show!!
            </div>
            }
            {dataOrders.length > 0 &&
            <Paginator
              cPage={currentPage}
              data={dataOrders}
              head={tHead}
              nElem={elementsAmount}
              handleOnClickBtn={handleCurrentPageChange}
              paginator={pagination}
              onChangeNElement={onChangeNElement}
            />
            }
          </>
          }
          {selectedOrder &&
          <div className="col-10">
            <div style={{display:'flex',flexDirection:'row', justifyContent:'start', alignItems:'center'}}>
              <FontAwesomeIcon icon={faLeftLong} onClick={() => {setSelectedOrder(null)}} />
              <h3 className={'ms-2'}>Order details</h3>
            </div>
            <br/>
            <h5>Order</h5>
            <div className={'card border-0 shadow-lg col-8'} >
             <div className={'card-body row'}>
               <div className={'col-2'}>
                 <p className={'fw-bold'}>Name:</p>
                 <p className={'fw-bold'}>Price:</p>
                 <p className={'fw-bold'}>Date:</p>
               </div>
               <div className={'col-3'}>
                 <p className={'fw-normal'}>{selectedOrder.shippingAddress.fullName}</p>
                 <p className={'fw-normal'}>{selectedOrder.priceString}</p>
                 <p className={'fw-normal'}>{selectedOrder.dateString}</p>
               </div>
               <div className={'col-2'}>
                 <p className={'fw-bold'}>Pone:</p>
                 <p className={'fw-bold'}>User Id:</p>
                 <p className={'fw-bold'}>Order Id:</p>
               </div>
               <div className={'col-4'}>
                 <p className={'fw-normal'}>{selectedOrder.user.phoneNumber}</p>
                 <p className={'fw-normal'}>{selectedOrder.user.id}</p>
                 <p className={'fw-normal'}>{selectedOrder.id}</p>
               </div>
               <div className={'col-2'}>
                 <p className={'fw-bold'}>Email:</p>
               </div>
               <div className={'col-3'}>
                 <p className={'fw-normal'}>{selectedOrder.user.email ?? '-'}</p>
               </div>
               <div className={'d-flex flex-row'}>
               <div className={'me-1'} style={{width:'fit-content'}} >
                 <p className={'fw-bold'}>Shipping address:</p>
               </div>
               <div className={''} style={{width:'fit-content'}} >
                 <p className={'fw-normal'}>{selectedOrder.shippingAddress.street + ', '
                     + selectedOrder.shippingAddress.postalCode + ' ' + selectedOrder.shippingAddress.city + ', '
                     + selectedOrder.shippingAddress.country}</p>
               </div>
               </div>

             </div>
            </div>
            <br/>
            <h5>Merchant</h5>
            <div className={'card border-0 shadow-lg col-8'}>
              <div className={'card-body'}>
                <div className={'row'}>
                  <div className={'col-2'}>
                    <p className={'fw-bold'}>Store:</p>
                    <p className={'fw-bold'}>Phone:</p>
                  </div>
                  <div className={'col-3'}>
                    <p className={'fw-normal'}>{selectedOrder.merchant.companyName}</p>
                    <p className={'fw-normal'}>{selectedOrder.merchant.phoneNumber}</p>
                  </div>
                  <div className={'col-2'}>
                    <p className={'fw-bold'}>Id:</p>
                    <p className={'fw-bold'}>Location:</p>
                  </div>
                  <div className={'col-5'}>
                    <p className={'fw-normal'}>{selectedOrder.merchant.id}</p>
                    {
                        selectedOrder.merchant.location.length > 0 ? <p className={'fw-normal'}
                         role={'button'}
                         onClick={() => {
                           window.open("https://www.google.com/maps/search/?api=1&query="+selectedOrder.merchant.location[1]+","+selectedOrder.merchant.location[0], '_blank');
                         }}
                      >{selectedOrder.merchant.location[1] + ' - ' + selectedOrder.merchant.location[0] + ' '}
                        <FontAwesomeIcon icon={faLocationDot} onClick={() => {setSelectedOrder(null)}} />
                      </p> : <p>-</p>
                    }
                  </div>
                  <div className={'col-12'}/>
                  <div className={'col-2'}>
                    <p className={'fw-bold'}>Address:</p>
                  </div>
                  <div className={'col-10'}>
                    <div className={'fw-normal'}>{selectedOrder.merchant.address+', '
                        +selectedOrder.merchant.postalCode+' '+selectedOrder.merchant.city+', '
                        +selectedOrder.merchant.country}</div>
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <h5>Products</h5>
            <div className={'card border-0 shadow-lg'}>
              <div className={'card-body'}>
                {selectedOrder.products.map(p => (
                    <div key={p.product.id}>
                      <div style={{width: '100%',  minHeight:110, flexDirection: 'row', marginTop:5}}>
                        <div style={{float:'left'}}>
                          {p.product.images && p.product.images.length > 0 && <><img src={p.product.images[0].thumbBigURL} width={110} height={110} /> &nbsp;&nbsp;&nbsp;</>}
                        </div>
                        <div style={{flexDirection: 'row'}}>
                          <span><strong>Id: </strong>{p.product.id}</span>
                          <br/>
                          <span><strong>Category: </strong>{p.product.category}</span>
                          <br/>
                          {p.product.dimensions &&
                              <>
                                <span><strong>Dimensions: </strong>{p.product.dimensions.width+'x'+p.product.dimensions.height+'x'+p.product.dimensions.depth}</span>
                                <br/>
                              </>
                          }
                          {p.product.dimensions && p.product.dimensions.weight &&
                              <>
                                <span><strong>Weight: </strong>{p.product.dimensions.weight}</span>
                                <br/>
                              </>
                          }
                          <span><strong>Price: </strong>{p.product.currency +' '+p.product.price}</span>
                          {p.product.share && p.product.share.URL &&
                              <>
                                <br/>
                                <span><strong>URL: </strong><a target="_blank" href={p.product.share.URL}>{p.product.share.URL}</a></span>
                              </>
                          }
                          <br/>
                          <span><strong>Description: </strong>{p.product.description}</span>
                        </div>
                      </div>
                    </div>
                ))}
              </div>
            </div>
            <br/>
          </div>
          }
        </div>)
    }
  }
  return (display())
}
export default Orders;
