import React, {useCallback, useEffect, useState} from "react";
import {useNavigate,useLocation} from "react-router-dom";
import {FilterCountry, LoadingModal, Paginator} from "../../commons";
import UserRow from "./views/UserRow";
import {codesCountry, N_ELEMENT_BY_PAGE} from '../../commons/configs'
import {useStoreActions, useStoreState} from "easy-peasy";

const Users = () =>{

    const {fetchingPagination,pagination,error,fetchingUsers,arrUsers,updating} = useStoreState((state) => state.users);
    const {
        fetchUsers,
        setData,
        setUserEnable
    } = useStoreActions((actions) => actions.users);
    const location = useLocation()
    const navigate = useNavigate();
    const [nElem,setNElem]=useState(N_ELEMENT_BY_PAGE)
    const [cPage,setCPage]=useState(1)
    const [code,setCode]=useState('ALL')
    const [dataUsers,setDataUsers]=useState([])
    const [filter,setFilter]=useState(null)
    const tHead = ["Phone Number",	"OS",	"OS version",	"Device",	"Modified",	"Created",	"# Twilio SMS",	"Confirmed",	"Access"]

    useEffect(()=>{
        const searchParams = new URLSearchParams(location.search)
        if(searchParams.has('countryCode')){
            fetchUsers({nElem, cPage:1,filter: {"phoneConfirmed": true, phonePrefix: codesCountry[searchParams.get('countryCode').toUpperCase()]}});
            setCode(searchParams.get('countryCode').toUpperCase())
            setFilter({phonePrefix:codesCountry[searchParams.get('countryCode').toUpperCase()]})
        }else{
            fetchUsers({nElem,cPage:1})
        }
    },[fetchUsers, location.search])

    const _setUserEnable = useCallback((userId, enabled)=>{
        setUserEnable({userId,enabled})
    },[setUserEnable])

    const _selectUser = useCallback((user)=>{
        setData({key:'userSelected', value:user});
        navigate('/users/'+user.phoneNumber);
    },[setData, navigate])

    const formatUsers = useCallback((users)=>{
        if(users !== undefined){
            setDataUsers(() => (users.map((u,k)=>(<UserRow
                setUserEnable={_setUserEnable}
                selectUser={_selectUser}
                key={k}
                data={u}
            />))));
        }
    },[_setUserEnable,_selectUser])

    useEffect(()=>{
        formatUsers(arrUsers)
    },[arrUsers,formatUsers])

    useEffect(()=>{
        if(nElem !== N_ELEMENT_BY_PAGE){
            fetchUsers({nElem, cPage:1, filter})
        }
    },[nElem,fetchUsers])

    const handleCPage = useCallback((evt)=>{
        evt.stopPropagation()
        const page = parseInt(evt.target.value)
        fetchUsers({nElem, cPage:page, filter})
        setCPage(page)
    },[fetchUsers,nElem,filter])

    const _handleCountry = useCallback((code)=>{
        navigate('/users?countryCode='+code)
        if(code !== 'ALL'){
            fetchUsers({nElem, cPage:1,filter: {"phoneConfirmed": true, phonePrefix: codesCountry[code]}});
            setCode(code)
            setFilter({phonePrefix:codesCountry[code]})
        }else{
            fetchUsers({nElem, cPage:1});
            setCode(code);
            setFilter(null);
        }
    },[nElem,fetchUsers,navigate])

    const onChangeNElement = useCallback(nElements=>{
        setNElem(nElements)
    },[])

    const display=()=>{

        if(fetchingPagination || fetchingUsers || updating){
           return( <>
               <FilterCountry code={code}  title={"Users"} handleCountry={_handleCountry}/>
               <LoadingModal isLoading={fetchingPagination || fetchingUsers || updating} />
               {
                   arrUsers.length > 0 && <Paginator
                       cPage={cPage}
                       data={dataUsers}
                       head={tHead}
                       nElem={nElem}
                       handleOnClickBtn={handleCPage}
                       paginator={pagination}
                       onChangeNElement={onChangeNElement}
                   />
               }

           </>)
        }

        if(dataUsers !== undefined){
            if(dataUsers.length === 0)
                return <div className="alert alert-danger" role="alert">
                No Users to show!!
            </div>
            else
                return( <>
                    {error && <>
                        <h1>Users</h1>
                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    </>}
                    <FilterCountry code={code} title={"Users"} handleCountry={_handleCountry}/>
                    {
                         <Paginator
                            cPage={cPage}
                            data={dataUsers}
                            head={tHead}
                            nElem={nElem}
                            handleOnClickBtn={handleCPage}
                            paginator={pagination}
                            onChangeNElement={onChangeNElement}
                        />
                    }

                </>)
        }
    }
     return (display())
 }
 export default Users;
