import {thunk} from "easy-peasy";
import axiosInstance from "../../../services/axios";

export const fetchSubscriptions = thunk(async (actions, payload) => {
    actions.setData({key:'isLoading',value:true});
    axiosInstance.get('/subscriptions').then(({data})=>{
        actions.setData({key:'subscriptions', value:data});
    }).catch(err=>{
        actions.setData({key:'error', value:err.errorCode === undefined ? 999 : err.errorCode})
    }).finally(()=> {
        actions.setData({key:'isLoading',value:false});
    })
});
