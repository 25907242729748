import React from "react";
const FilterCountry = (props)=>{
   const {code,title}=props

    const _handleCountry=(evt)=>{
        evt.stopPropagation()
        props.handleCountry(evt.target.value)
    }

    return (
        <div
            className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
            <h1 className="h2">{title}</h1>
            <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group mr-2">
                    <button className={"btn btn-sm  " + (code === 'ALL'?"btn-secondary":"btn-outline-secondary")} value={'ALL'} onClick={_handleCountry}>ALL</button>
                    <button className={"btn btn-sm  " + (code === 'ES'?"btn-secondary":"btn-outline-secondary")} value={'ES'} onClick={_handleCountry}>ES</button>
                    <button className={"btn btn-sm  " + (code === 'NL'?"btn-secondary":"btn-outline-secondary")} value={'NL'} onClick={_handleCountry}>NL</button>
                    <button className={"btn btn-sm  " + (code === 'GB'?"btn-secondary":"btn-outline-secondary")} value={'GB'} onClick={_handleCountry}>GB</button>
                    <button className={"btn btn-sm  " + (code === 'FR'?"btn-secondary":"btn-outline-secondary")} value={'FR'} onClick={_handleCountry}>FR</button>
                    <button className={"btn btn-sm  " + (code === 'DE'?"btn-secondary":"btn-outline-secondary")} value={'DE'} onClick={_handleCountry}>DE</button>
                    <button className={"btn btn-sm  " + (code === 'US'?"btn-secondary":"btn-outline-secondary")} value={'US'} onClick={_handleCountry}>US</button>
                </div>
            </div>


        </div>
    )
}

export default  FilterCountry
