export const API_URL= 'https://api.goyuge.com/v1';
export const N_ELEMENT_BY_PAGE=15
export const codesCountry = Object.freeze({
    'ES':'+34',
    'NL':'+31',
    'GB':'+44',
    'FR':'+33',
    'DE':'+49',
    'US':'+1',
})

// using live true will use services in production, using false will use services in development
export const LIVE = true//window.location.href.includes('https://dashboard.goyuge.com');


