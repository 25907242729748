import React from "react";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEraser} from "@fortawesome/free-solid-svg-icons";

export const PhotoRow = ({data,selectMerchant,deletePhoto}) => {
    const {id,images,merchant,region,user,handle,text,photoshoot,created} = data;
    const _selectMerchant=evt=>{
        evt.preventDefault()
        selectMerchant(merchant)
    }

    return (<tr>
        <td className={"text-center"}><img src={images[0].URL} style={{width: '40px', height: '40px', borderRadius:'4px'}} alt=""/></td>
        <td className="align-middle ps-1"><a onClick={_selectMerchant} style={{cursor: 'pointer',textDecorationLine: 'underline',color: '#007bff'}}> {merchant !== null ? merchant.companyName:''}</a></td>
        <td className="align-middle text-center">{region}</td>
        <td className="align-middle ps-2">{user}</td>
        <td className="align-middle ps-4">{handle}</td>
        <td className="align-middle ps-4">{text}</td>
        <td className="align-middle text-center">{photoshoot}</td>
        <td className="align-middle">{moment(created).format("YYYY/MM/DD HH:mm")}</td>
        <td className="align-middle" style={{minWidth:'110px'}}>
            <button type="button" className="btn btn-sm w-100 btn-outline-danger" onClick={() => deletePhoto(id)}>
                <FontAwesomeIcon color={'#dc3545'} icon={faEraser}/>
                <span className="ml-1">Delete</span>
            </button>
        </td>
    </tr>)
}
